import { ActiveCompany } from '@bbkAdminRedux/app/reducers';
import { bbkApiSlice } from '@bbkAdminRedux/rtkq/bbkApi.slice';

export type InvitationMetadata = {
  teams?: string[];
  isBillingAdmin?: boolean;
  cb_idp_email_sent?: boolean;
  cb_invitation_url?: string;
};
export type Invitation = {
  id: number;
  email: string;
  company: string;
  company_obj: ActiveCompany;
  display_name: string;
  created_at: Date;
  expires_at: Date;
  code: string;
  metadata?: InvitationMetadata;
};

export type InviteResp = {
  sent: string[];
  existing: string[];
  invalid: string[];
};

export const invitationSlice = bbkApiSlice.injectEndpoints({
  endpoints: (build) => ({
    getPendingInvitationsByCompany: build.query<Invitation[], string>({
      query: (companyKey: string) =>
        `/api/v1/invitesByCompany?company=${companyKey}`,
      providesTags: ['PendingInvitesByCompany'],
    }),
    getPendingInvitationsByEmail: build.query<Invitation[], void>({
      query: () => `/api/v1/invitesByUser?include_blacklisted=true`,
      providesTags: ['PendingInvitesByEmail'],
    }),
    inviteUser: build.mutation<
      InviteResp,
      {
        company: string;
        emails: string;
        teams: string[];
        isBillingAdmin: boolean;
      }
    >({
      query: (args) => ({
        url: `/api/invite`,
        method: 'POST',
        body: args,
      }),
      invalidatesTags: ['PendingInvitesByCompany', 'PendingInvitesByEmail'],
    }),
    redeemInviteById: build.mutation<void, number>({
      query: (invitationId: number) => ({
        url: `/api/redeemInvite?id=${invitationId}`,
        method: 'POST',
      }),
      invalidatesTags: ['PendingInvitesByCompany', 'PendingInvitesByEmail'],
    }),
  }),
});

export const useSelectInvitesByUserQuery = (): Invitation[] => {
  const { data } =
    invitationSlice.endpoints.getPendingInvitationsByEmail.useQuery();
  return data ?? [];
};
