// @ts-nocheck
import React from 'react';
import _ from 'lodash';
import type {
  RestModalCard,
  RestModalForwards,
} from '@bbkAdminUtils/api-client/rest-modals-cards-types';
import {
  RestModalAction,
  RestModalActionHumanString,
  RestModalTemplates,
} from '@bbkAdminUtils/api-client/rest-modals-cards-types';
import type { GroupLayout } from '@bbkAdminComponents/experiences/offers/editor-layouts/group-layouts';
import {
  createOtherActionItem,
  otherActionsGenerics,
} from '@bbkAdminComponents/experiences/offers/editor-layouts/group-layouts';
import {
  AcceptOfferActionGroupLayout,
  CancelLinkActionGroupLayout,
  DismissModalAction,
  EmailSuccessSubtextLayout,
  OfferCardPackAdditionalActionLayout,
  IntercomChatActionGroupLayout,
  LinkActionGroupLayout,
  ModalEmailActionGroupLayout,
  NevermindLinkActionGroupLayout,
  SendEmailActionGroupLayout,
} from '@bbkAdminComponents/experiences/offers/editor-layouts/action-layouts';
import type {
  CardLayout,
  CardLayoutItem,
} from '@bbkAdminComponents/experiences/offers/editor-layouts/card-layouts';
import {
  EmailSendModalLayout,
  EmailSuccessModalLayout,
  FiftyFiftyColumnLayout,
  FinalOfferEditCardLayout,
  FortySixtyModal1Layout,
  FortySixtyModal2Layout,
  FortySixtyModal3Layout,
  FortySixtyModal4Layout,
  LossActivityLossAversionLayout,
  LossSimpleBlurbLossAversionLayout,
  LossSimpleLinkLossAversionLayout,
  LossSimpleListLossAversionLayout,
  LossTweetLossAversionLayout,
  OneUpImageLossAversionLayout,
  RestModalMultiCTATypeIEditCardLayout,
  RestModalMultiCTATypeIIEditCardLayout,
  SeventyThirtyRowLayout,
  SixtyFortyColumnActionModalLayout,
  SixtyFortyColumnCodeModalLayout,
  SixtyFortyColumnG2ModalLayout,
  SixtyFortyColumnModalLayout,
  SixtyFortyColumnTwitterModalLayout,
  SpeechBubble1Layout,
  SpeechBubble2Layout,
  StructuredContentModalLayout,
  VideoRowLayout,
} from '@bbkAdminComponents/experiences/offers/editor-layouts/card-layouts';
import { ActionSelect } from '@bbkAdminComponents/experiences/offers/action-select';
import { getModalContentForTemplate } from '@bbkAdminComponents/experiences/offers/content/modal-content-templates';
import { RestLossAversionCardTemplates } from '@bbkAdminUtils/api-client/rest-la-cards-types';
import type { RestLossAversionCardOrModalCard } from '@bbkAdminUtils/api-client/rest-cards-types';
import { isLossAversionCard } from '@bbkAdminUtils/api-client/rest-cards-types';
import type { RestOffer } from '@bbkAdminUtils/api-client/rest-offers-api';
import type { FeatureFlagsE } from '@bbkAdminUtils/feature-flags';
import {
  InlineEditorsTemplates,
  TemplateConfigs,
} from '@bbkAdminComponents/experiences/offers/inline-editors';

export function getDefaultActionContent(
  actionName: RestModalAction | RestModalTemplates.final_offer
): RestModalForwards | null {
  switch (actionName) {
    case RestModalAction.modal_email:
      return {
        action: RestModalAction.modal_email,
        text: 'Send message',
        icon: 'bbk-esi-message',
        modal: '',
        sync: {},
      };
    case RestModalAction.modal_email_structured:
      return {
        action: RestModalAction.modal_email_structured,
        text: 'Send message',
        icon: 'bbk-esi-message',
        url: '',
        modal: '',
        sync: {},
      };
    case RestModalAction.send_email:
      return {
        action: RestModalAction.send_email,
        text: 'Send message',
        icon: 'bbk-esi-message',
        url: '',
        modal: '',
        sync: {
          message: 'Message from the offer',
        },
      };
    case RestModalAction.link:
      return {
        action: RestModalAction.link,
        icon: 'none',
        text: 'Book a time',
        url: 'https://www.brightback.com',
        sync: {},
      };
    case RestModalAction.accept_offer_with_confirmation:
      return {
        action: RestModalAction.accept_offer_with_confirmation,
        icon: 'none',
        text: 'Accept offer',
        modal: '',
        sync: {},
      };
    case RestModalAction.cancel_with_link:
      return {
        action: RestModalAction.cancel_with_link,
        text: 'Cancel',
        url: 'https://www.brightback.com',
        sync: {},
      };
    case RestModalAction.nevermind:
      return {
        action: RestModalAction.nevermind,
        text: 'Go back to my account',
        url: 'https://www.brightback.com',
        sync: {},
      };
    case RestModalAction.intercom_chat:
      return {
        action: RestModalAction.intercom_chat,
        text: "Let's chat",
        icon: 'bbk-esi-chat',
        initial_message:
          'I would like to speak to someone further about your product.',
        sync: {},
      };
    case RestModalAction.dismiss_modal:
      return {
        action: RestModalAction.dismiss_modal,
        text: 'Continue to cancel',
        sync: {},
      };
    case RestModalTemplates.final_offer:
      return {
        action: RestModalTemplates.final_offer,
        generic_actions: [
          {
            text: 'Accept offer',
            action: RestModalAction.link,
            url: 'https://www.brightback.com?accept_offer',
            sync: {},
          },
          {
            text: 'Decline and cancel immediately',
            action: RestModalAction.cancel_with_link,
            url: 'https://www.brightback.com?cancel',
            sync: {},
          },
        ],
      };
    default:
      return null;
  }
}

export function getDefaultActionSteps(
  offer: RestOffer,
  newAction: RestModalAction
): RestModalCard[] {
  switch (newAction) {
    case RestModalAction.modal_email: {
      const sendModal = getModalContentForTemplate(
        _.snakeCase(offer.display_name),
        RestModalTemplates.modal_email_send
      );
      const successModal = getModalContentForTemplate(
        _.snakeCase(offer.display_name),
        RestModalTemplates.modal_email_success
      );
      _.set(sendModal, 'requires.forwards.modal', successModal.name);
      return [sendModal, successModal];
    }
    case RestModalAction.modal_email_structured: {
      const structuredModal = getModalContentForTemplate(
        _.snakeCase(offer.display_name),
        RestModalTemplates.modal_email_structured
      );
      const successModalES = getModalContentForTemplate(
        _.snakeCase(offer.display_name),
        RestModalTemplates.modal_email_success
      );
      _.set(structuredModal, 'requires.forwards.modal', successModalES.name);
      return [structuredModal, successModalES];
    }
    case RestModalAction.send_email: {
      const sendEmailSuccessModal = getModalContentForTemplate(
        _.snakeCase(offer.display_name),
        RestModalTemplates.modal_email_success
      );
      return [sendEmailSuccessModal];
    }
    case RestModalAction.accept_offer_with_confirmation: {
      const acceptSuccessModal = getModalContentForTemplate(
        _.snakeCase(offer.display_name),
        'acceptOfferSuccess'
      );
      return [acceptSuccessModal];
    }
    default: {
      return [];
    }
  }
}

function getBaseLayoutConfig<
  T extends RestLossAversionCardOrModalCard = RestLossAversionCardOrModalCard,
>(card: T): CardLayout<T> {
  switch (card.template) {
    case RestModalTemplates.forty_sixty_mini_modal_1:
      return FortySixtyModal1Layout;
    case RestModalTemplates.forty_sixty_mini_modal_2:
      return FortySixtyModal2Layout;
    case RestModalTemplates.forty_sixty_mini_modal_3:
      return FortySixtyModal3Layout;
    case RestModalTemplates.forty_sixty_mini_modal_4:
      return FortySixtyModal4Layout;
    case RestModalTemplates.speech_bubble_1:
      return SpeechBubble1Layout;
    case RestModalTemplates.speech_bubble_2:
      return SpeechBubble2Layout;
    case RestModalTemplates.video_row:
      return VideoRowLayout;
    case RestModalTemplates.fifty_fifty_column:
      return FiftyFiftyColumnLayout;
    case RestModalTemplates.seventy_thirty_row:
      return SeventyThirtyRowLayout;
    case RestModalTemplates.sixty_forty_column:
      return SixtyFortyColumnModalLayout;
    case RestModalTemplates.sixty_forty_column_action:
      return SixtyFortyColumnActionModalLayout;
    case RestModalTemplates.sixty_forty_column_code:
      return SixtyFortyColumnCodeModalLayout;
    case RestModalTemplates.sixty_forty_column_twitter:
      return SixtyFortyColumnTwitterModalLayout;
    case RestModalTemplates.sixty_forty_column_g2:
      return SixtyFortyColumnG2ModalLayout;
    case RestModalTemplates.modal_email_send:
      return EmailSendModalLayout;
    case RestModalTemplates.modal_email_success:
      return EmailSuccessModalLayout;
    case RestModalTemplates.modal_email_structured:
      return StructuredContentModalLayout;
    case RestModalTemplates.multi_cta_type_I:
      return RestModalMultiCTATypeIEditCardLayout;
    case RestModalTemplates.multi_cta_type_II:
      return RestModalMultiCTATypeIIEditCardLayout;
    case RestModalTemplates.final_offer:
      return FinalOfferEditCardLayout;

    // LAs
    case RestLossAversionCardTemplates.la_one_up_image:
      return OneUpImageLossAversionLayout;
    case RestLossAversionCardTemplates.loss_simple_link:
      return LossSimpleLinkLossAversionLayout;
    case RestLossAversionCardTemplates.simple_list:
      return LossSimpleListLossAversionLayout;
    case RestLossAversionCardTemplates.simple_blurb:
      return LossSimpleBlurbLossAversionLayout;
    case RestLossAversionCardTemplates.simple_chip:
      return LossActivityLossAversionLayout;
    case RestLossAversionCardTemplates.la_twitter_card:
      return LossTweetLossAversionLayout;
    default:
      return [];
  }
}

export const prepareLayout = (
  card: RestLossAversionCardOrModalCard,
  layout: CardLayout<any>
) => {
  return layout.flatMap((group) => {
    if (typeof group === 'function') {
      return group(card.requires);
    }
    return group;
  });
};

export const getBaseLayout: typeof getBaseLayoutConfig = (card) => {
  const layout = getBaseLayoutConfig(card);
  return prepareLayout(card, layout);
};

function usesOtherActions<
  T extends RestLossAversionCardOrModalCard = RestLossAversionCardOrModalCard,
>(card: T): boolean {
  if (
    card.template === RestModalTemplates.final_offer ||
    _.has(card, 'requires.generic_actions')
  ) {
    return false;
  }

  return true;
}

export function getGenericActionLayout(): GroupLayout<RestModalForwards> {
  return [
    {
      key: 'action',
      editor: {
        label: 'Type',
        node: ({ value, patchPath, onChange, ...restProps }) => (
          <ActionSelect
            value={value}
            onChange={(e) => onChange(patchPath, e.value)}
            {...restProps}
          />
        ),
      },
    },
  ];
}

function getSpecificActionLayoutItem(
  itemAction: RestModalAction,
  card?: RestLossAversionCardOrModalCard
): GroupLayout<RestModalForwards> {
  switch (itemAction) {
    case RestModalAction.intercom_chat:
      return IntercomChatActionGroupLayout;
    case RestModalAction.modal_email:
      return ModalEmailActionGroupLayout;
    case RestModalAction.modal_email_structured:
      return ModalEmailActionGroupLayout;
    case RestModalAction.send_email:
      switch (card?.template) {
        case RestModalTemplates.modal_email_structured:
          return [...SendEmailActionGroupLayout.slice(1)];
      }
      return SendEmailActionGroupLayout;
    case RestModalAction.accept_offer:
      return AcceptOfferActionGroupLayout;
    case RestModalAction.accept_offer_with_confirmation:
      return AcceptOfferActionGroupLayout;
    case RestModalAction.link:
      return LinkActionGroupLayout;
    case RestModalAction.cancel_with_link:
      return CancelLinkActionGroupLayout;
    case RestModalAction.nevermind:
      return NevermindLinkActionGroupLayout;
    case RestModalAction.dismiss_modal:
      return DismissModalAction;
    default:
      return [];
  }
}

function getSpecificActionLayoutCardItem(
  card: RestLossAversionCardOrModalCard
) {
  switch (card.template) {
    case RestModalTemplates.modal_email_success:
      return EmailSuccessSubtextLayout;
    case RestModalTemplates.offer_card_pack:
      return [...LinkActionGroupLayout, ...OfferCardPackAdditionalActionLayout];
    default:
      return [];
  }
}

export function getSpecificActionLayout(
  card: RestLossAversionCardOrModalCard
): GroupLayout<RestModalForwards> {
  return getSpecificActionLayoutItem(
    _.get(card, 'requires.forwards.action'),
    card
  );
}

export function getForwardsActionLayout(
  card: RestLossAversionCardOrModalCard
): GroupLayout<RestModalForwards> {
  if (_.has(card, 'requires.forwards') || _.has(card, 'requires.content_set')) {
    return [
      {
        key: 'forwards',
        label: 'Action',
        layout: [
          ...getGenericActionLayout(),
          ...getSpecificActionLayout(card),
          ...getSpecificActionLayoutCardItem(card),
        ],
      },
    ];
  }
  return [];
}

export const getGenericActionsItemLayout = (
  card: RestLossAversionCardOrModalCard,
  idx: number
) => {
  const ga = card.requires.generic_actions[idx];
  return {
    key: `generic_actions.${idx}`,
    label: `${idx + 1}. ${RestModalActionHumanString[ga.action]}`,
    layout: getSpecificActionLayoutItem(ga.action, card),
  };
};

export function getGenericActionsLayout(
  card: RestLossAversionCardOrModalCard
): GroupLayout<RestModalForwards> {
  if (_.has(card, 'requires.generic_actions')) {
    return card.requires.generic_actions.map((ga, idx) =>
      getGenericActionsItemLayout(card, idx)
    );
  }
  return [];
}

export function getActionLayout(
  card: RestLossAversionCardOrModalCard
): GroupLayout<RestModalForwards> {
  const forwardsActionLayout = getForwardsActionLayout(card);
  if (forwardsActionLayout.length !== 0) return forwardsActionLayout;

  const genericActionsLayout = getGenericActionsLayout(card);
  if (genericActionsLayout.length !== 0) return genericActionsLayout;

  return [];
}

export function getOtherActionLayout(
  card: RestLossAversionCardOrModalCard
): CardLayoutItem {
  const returnItem = {
    key: 'other_actions',
    label: 'Additional actions',
    isVariableArray: true,
    arrayLimit: 2,
    layouts: [],
    generics: otherActionsGenerics,
    groupPath: 'requires.',
  };
  if (card?.requires?.other_actions) {
    const otherActions: RestModalForwards[] = _.get(
      card,
      'requires.other_actions'
    );
    const otherActionsLayout = otherActions.map((oa, index) => [
      ...getGenericActionLayout(),
      ...getSpecificActionLayoutItem(oa.action, card),
    ]);
    return {
      ...returnItem,
      layouts: otherActionsLayout,
    };
  }

  return {
    key: 'other_actions',
    label: 'Additional actions',
    createItem: true,
    baseValue: {},
    createLayout: createOtherActionItem,
  };
}

function getOtherActionsLayout(
  card: RestLossAversionCardOrModalCard,
  featureFlags?: Record<FeatureFlagsE, boolean | undefined>
): CardLayout<any> {
  if (
    featureFlags?.EXPERIENCE_MANAGER_OTHER_ACTION_ACCESSIBILE &&
    !isLossAversionCard(card) &&
    usesOtherActions(card)
  ) {
    const otherActionLayout = getOtherActionLayout(card);
    if (otherActionLayout) {
      return [otherActionLayout];
    }
  }
  return [];
}

export function getLayout(
  card: RestLossAversionCardOrModalCard,
  featureFlags?: Record<FeatureFlagsE, boolean | undefined>
): CardLayout<any> {
  const baseLayout = getBaseLayout(card);
  const actionLayout = getActionLayout(card);
  const otherActionsLayout = getOtherActionsLayout(card, featureFlags);

  // Hide base layout from sidebar for the templates that already have inline editors
  //TODO AbK - some LA cards with offers have partial inline editing capabilities
  const templateConfig = TemplateConfigs[card.template];
  return templateConfig && templateConfig.partialInline === false
    ? []
    : [...baseLayout, ...actionLayout, ...otherActionsLayout];
}
