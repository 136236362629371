// @ts-nocheck
import type { RefObject } from 'react';
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { usePrevious } from 'react-use';
import { css } from '@emotion/react';
import type { PopoverOrigin } from '@material-ui/core/Popover/Popover';
import _ from 'lodash';
import type { ReactFCC } from '@bbkAdminUtils/utility-types';
import { RefsContext } from '@bbkFunnelUtils/refs.context';
import type { RestModalCard } from '@bbkAdminUtils/api-client/rest-modals-cards-types';
import { RestModalTemplates } from '@bbkAdminUtils/api-client/rest-modals-cards-types';
import { ButtonV2 } from '@bbkAdminComponents/buttons';
import Icons from '@bbkAdminUtils/icons';
import { GenericInlineEditor } from '@bbkAdminComponents/experiences/offers/editors/generic-inline-editor';
import {
  getActionLayout,
  getBaseLayout,
  getForwardsActionLayout,
  getGenericActionsLayout,
  getOtherActionLayout,
  prepareLayout,
} from '@bbkAdminComponents/experiences/offers/content/action-content';
import { EditOfferPageContext } from '@bbkAdminComponents/experiences/offers/edit-offer-page-context';
import { RestLossAversionCardTemplates } from '@bbkAdminUtils/api-client/rest-la-cards-types';
import type { CardLayout } from '@bbkAdminComponents/experiences/offers/editor-layouts/card-layouts';
import {
  EmailSendModalLayout,
  EmailSuccessModalLayout,
  FinalOfferEditCardLayout,
  FortySixtyModal1Layout,
  FortySixtyModal2Layout,
  LossSimpleLinkLossAversionLayout,
  LossSimpleListLossAversionLayout,
  LossActivityLossAversionLayout,
  OneUpImageLossAversionLayout,
  RestModalMultiCTATypeIEditCardLayout,
  RestModalMultiCTATypeIIEditCardLayout,
  SixtyFortyColumnModalLayout,
  SpeechBubble1Layout,
  SpeechBubble2Layout,
  StructuredContentModalLayout,
  VideoRowLayout,
  LCOModalLayout,
  LCOPopupLayout,
  LCOBannerLayout,
  OfferCardPackLayout,
} from '@bbkAdminComponents/experiences/offers/editor-layouts/card-layouts';
import { useResizeObserver } from '@bbkAdminComponents/hooks/useResizeObserver';
import { CardGenericLayoutEditor } from '@bbkAdminComponents/experiences/offers/card-generic-layout-editor';
import { isDefined } from '@bbkAdminRedux/rtkq/rtkq-utils';
import { isOfferCardPack } from '@bbkAdminUtils/api-client/rest-cards-types';

export const EditButton: ReactFCC<React.ComponentProps<typeof ButtonV2>> = ({
  children,
  ...restProps
}) => (
  <ButtonV2
    circle
    small
    css={css`
      padding: 0;
      z-index: 1;
    `}
    {...restProps}
  >
    {children ?? Icons.pencil}
  </ButtonV2>
);

type InlineEditButtonAnchorOrigin = PopoverOrigin & {
  verticalOffset?: number;
  horizontalOffset?: number;
};

const EDIT_BUTTON_WIDTH = 24;
const EDIT_BUTTON_HEIGHT = 24;

const calcTop = (el: HTMLElement, opts?: InlineEditButtonAnchorOrigin) => {
  const initTop = el.offsetTop;
  switch (opts?.vertical) {
    case 'top':
      return initTop - EDIT_BUTTON_HEIGHT;
    case 'center':
      return initTop + el.offsetHeight / 2 - EDIT_BUTTON_HEIGHT / 2;
    case 'bottom':
      return initTop + el.offsetHeight;
    default:
      return initTop + (opts?.vertical ?? 0);
  }
};
const calcLeft = (el: HTMLElement, opts?: InlineEditButtonAnchorOrigin) => {
  const initLeft = el.offsetLeft;
  switch (opts?.horizontal) {
    case 'left':
      return initLeft - EDIT_BUTTON_WIDTH;
    case 'center':
      return initLeft + el.offsetWidth / 2 - EDIT_BUTTON_WIDTH / 2;
    case 'right':
      return initLeft + el.offsetWidth;
    default:
      return initLeft + (opts?.horizontal ?? 0);
  }
};

type InlineEditorsConfig = {
  layout?: CardLayout<any>;
  anchorOrigin: InlineEditButtonAnchorOrigin;
};

export const TemplateConfigs: Record<
  RestModalTemplates | RestLossAversionCardTemplates,
  Record<string, InlineEditorsConfig>
> = {
  [RestModalTemplates.forty_sixty_mini_modal_1]: {
    title: {
      layout: [
        {
          ...FortySixtyModal1Layout[1],
          layout: [FortySixtyModal1Layout[1].layout[0]],
        },
      ],
      anchorOrigin: {
        vertical: 'top',
        horizontal: 'right',
        verticalOffset: EDIT_BUTTON_HEIGHT * 1.125,
        horizontalOffset: EDIT_BUTTON_WIDTH / 2,
      },
    },
    person: {
      layout: [FortySixtyModal1Layout[0]],
      anchorOrigin: {
        vertical: 'bottom',
        horizontal: 'center',
      },
    },
    desc: {
      layout: [
        {
          ...FortySixtyModal1Layout[1],
          layout: [FortySixtyModal1Layout[1].layout[1]],
        },
      ],
      anchorOrigin: {
        vertical: 'top',
        horizontal: 'right',
        verticalOffset: EDIT_BUTTON_HEIGHT,
        horizontalOffset: EDIT_BUTTON_WIDTH / 2,
      },
    },
    actions: {
      anchorOrigin: {
        vertical: 'top',
        horizontal: 'right',
        verticalOffset: EDIT_BUTTON_HEIGHT * 1.375,
        horizontalOffset: EDIT_BUTTON_WIDTH / 2,
      },
    },
  },
  [RestModalTemplates.forty_sixty_mini_modal_2]: {
    title: {
      layout: [
        {
          ...FortySixtyModal2Layout[1],
          layout: [FortySixtyModal2Layout[1].layout[0]],
        },
      ],
      anchorOrigin: {
        vertical: 'top',
        horizontal: 'right',
        verticalOffset: EDIT_BUTTON_HEIGHT * 1.125,
        horizontalOffset: EDIT_BUTTON_WIDTH / 2,
      },
    },
    person: {
      layout: [FortySixtyModal2Layout[0]],
      anchorOrigin: {
        vertical: 'bottom',
        horizontal: 'center',
        verticalOffset: -1 * (EDIT_BUTTON_HEIGHT / 2),
      },
    },
    desc: {
      layout: [
        {
          ...FortySixtyModal2Layout[1],
          layout: [FortySixtyModal2Layout[1].layout[1]],
        },
      ],
      anchorOrigin: {
        vertical: 'top',
        horizontal: 'right',
        verticalOffset: EDIT_BUTTON_HEIGHT,
        horizontalOffset: EDIT_BUTTON_WIDTH / 2,
      },
    },
    actions: {
      anchorOrigin: {
        vertical: 'center',
        horizontal: 'right',
        horizontalOffset: EDIT_BUTTON_WIDTH / 2,
      },
    },
  },
  [RestModalTemplates.speech_bubble_1]: {
    person: {
      layout: [SpeechBubble1Layout[0]],
      anchorOrigin: {
        vertical: 'top',
        horizontal: 'right',
        verticalOffset: 105 / 4,
        horizontalOffset: -1 * (EDIT_BUTTON_WIDTH * 1.5),
      },
    },
    desc: {
      layout: [SpeechBubble1Layout[1]],
      anchorOrigin: {
        vertical: 'top',
        horizontal: 'right',
        verticalOffset: EDIT_BUTTON_HEIGHT,
        horizontalOffset: EDIT_BUTTON_WIDTH / 2,
      },
    },
    actions: {
      anchorOrigin: {
        vertical: 'top',
        horizontal: 'right',
        verticalOffset: EDIT_BUTTON_HEIGHT * 2,
        horizontalOffset: EDIT_BUTTON_WIDTH / 2,
      },
    },
  },
  [RestModalTemplates.speech_bubble_2]: {
    title: {
      layout: [
        {
          ...SpeechBubble2Layout[1],
          layout: [SpeechBubble2Layout[1].layout[0]],
        },
      ],
      anchorOrigin: {
        vertical: 'top',
        horizontal: 'right',
        verticalOffset: EDIT_BUTTON_HEIGHT * 1.125,
        horizontalOffset: EDIT_BUTTON_WIDTH / 2,
      },
    },
    image: {
      layout: [
        {
          ...SpeechBubble2Layout[0],
          layout: [SpeechBubble2Layout[0].layout[0]],
        },
      ],
      anchorOrigin: {
        vertical: 'top',
        horizontal: 'right',
        verticalOffset: 105 / 4,
        horizontalOffset: -1 * (105 / 1.65),
      },
    },
    person: {
      layout: [
        {
          ...SpeechBubble2Layout[0],
          layout: [
            SpeechBubble2Layout[0].layout[1],
            SpeechBubble2Layout[0].layout[2],
            SpeechBubble2Layout[0].layout[3],
            SpeechBubble2Layout[0].layout[4],
          ],
        },
      ],
      anchorOrigin: {
        vertical: 'bottom',
        horizontal: 'center',
      },
    },
    desc: {
      layout: [
        {
          ...SpeechBubble2Layout[1],
          layout: [SpeechBubble2Layout[1].layout[1]],
        },
      ],
      anchorOrigin: {
        vertical: 'top',
        horizontal: 'right',
        verticalOffset: EDIT_BUTTON_HEIGHT,
        horizontalOffset: EDIT_BUTTON_WIDTH / 2,
      },
    },
    actions: {
      anchorOrigin: {
        vertical: 'top',
        horizontal: 'right',
        horizontalOffset: EDIT_BUTTON_WIDTH / 2,
        verticalOffset: EDIT_BUTTON_HEIGHT * 1.375,
      },
    },
  },
  [RestModalTemplates.video_row]: {
    title: {
      layout: [
        {
          ...VideoRowLayout[1],
          layout: [VideoRowLayout[1].layout[0]],
        },
      ],
      anchorOrigin: {
        vertical: 'center',
        horizontal: 'right',
        horizontalOffset: 4,
      },
    },
    video: {
      layout: [VideoRowLayout[0]],
      anchorOrigin: {
        vertical: 'top',
        horizontal: 'right',
        verticalOffset: EDIT_BUTTON_HEIGHT / 1.5,
        horizontalOffset: -1 * (EDIT_BUTTON_WIDTH / 1.5),
      },
    },
    desc: {
      layout: [
        {
          ...VideoRowLayout[1],
          layout: [VideoRowLayout[1].layout[1]],
        },
      ],
      anchorOrigin: {
        vertical: 'top',
        horizontal: 'right',
        verticalOffset: 24,
        horizontalOffset: -1 * (EDIT_BUTTON_WIDTH + 4),
      },
    },
    actions: {
      anchorOrigin: {
        vertical: 'top',
        horizontal: 'right',
        horizontalOffset: EDIT_BUTTON_WIDTH / 2,
        verticalOffset: EDIT_BUTTON_HEIGHT * 1.375,
      },
    },
  },
  [RestModalTemplates.sixty_forty_column]: {
    image: {
      layout: [SixtyFortyColumnModalLayout[0]],
      anchorOrigin: {
        vertical: 'top',
        horizontal: 'right',
        verticalOffset: EDIT_BUTTON_HEIGHT + 4,
        horizontalOffset: -1 * (EDIT_BUTTON_WIDTH + 4),
      },
    },
    title: {
      layout: [
        {
          ...SixtyFortyColumnModalLayout[1],
          layout: [SixtyFortyColumnModalLayout[1].layout[0]],
        },
      ],
      anchorOrigin: {
        vertical: 'top',
        horizontal: 'right',
        verticalOffset: EDIT_BUTTON_HEIGHT * 1.25,
        horizontalOffset: 4,
      },
    },
    desc: {
      layout: [
        {
          ...SixtyFortyColumnModalLayout[1],
          layout: [SixtyFortyColumnModalLayout[1].layout[1]],
        },
      ],
      anchorOrigin: {
        vertical: 'top',
        horizontal: 'right',
        verticalOffset: EDIT_BUTTON_HEIGHT,
        horizontalOffset: 4,
      },
    },
    actions: {
      anchorOrigin: {
        vertical: 'top',
        horizontal: 'right',
        verticalOffset: EDIT_BUTTON_HEIGHT * 1.375,
        horizontalOffset: 4,
      },
    },
  },
  [RestModalTemplates.lco_banner]: {
    image: {
      layout: [LCOBannerLayout[0]],
      anchorOrigin: {
        vertical: 'center',
        horizontal: 'right',
        verticalOffset: 0,
        horizontalOffset: -1 * (EDIT_BUTTON_WIDTH / 2),
      },
    },
    desc: {
      layout: [
        {
          ...LCOBannerLayout[1],
          layout: [LCOBannerLayout[1].layout[1]],
        },
      ],
      anchorOrigin: {
        vertical: 'center',
        horizontal: 'right',
        verticalOffset: 0,
        horizontalOffset: EDIT_BUTTON_WIDTH / 2,
      },
    },
    actions: {
      anchorOrigin: {
        vertical: 'center',
        horizontal: 'right',
        verticalOffset: EDIT_BUTTON_HEIGHT / 8,
        horizontalOffset: -1 * (EDIT_BUTTON_WIDTH / 2),
      },
    },
  },
  [RestModalTemplates.lco_modal]: {
    image: {
      layout: [LCOModalLayout[0]],
      anchorOrigin: {
        vertical: 'top',
        horizontal: 'right',
        verticalOffset: EDIT_BUTTON_HEIGHT + 4,
        horizontalOffset: -1 * (EDIT_BUTTON_WIDTH + 4),
      },
    },
    title: {
      layout: [
        {
          ...LCOModalLayout[1],
          layout: [LCOModalLayout[1].layout[0]],
        },
      ],
      anchorOrigin: {
        vertical: 'top',
        horizontal: 'right',
        verticalOffset: EDIT_BUTTON_HEIGHT * 1.25,
        horizontalOffset: 4,
      },
    },
    desc: {
      layout: [
        {
          ...LCOModalLayout[1],
          layout: [LCOModalLayout[1].layout[1]],
        },
      ],
      anchorOrigin: {
        vertical: 'top',
        horizontal: 'right',
        verticalOffset: EDIT_BUTTON_HEIGHT,
        horizontalOffset: 4,
      },
    },
    actions: {
      anchorOrigin: {
        vertical: 'top',
        horizontal: 'right',
        verticalOffset: EDIT_BUTTON_HEIGHT * 1.375,
        horizontalOffset: 4,
      },
    },
  },
  [RestModalTemplates.offer_card_pack]: {
    image: {
      layout: [OfferCardPackLayout[0]],
      anchorOrigin: {
        vertical: 'center',
        horizontal: 'right',
        verticalOffset: 0,
        horizontalOffset: -1 * (EDIT_BUTTON_WIDTH / 2),
      },
    },
    title: {
      layout: [
        {
          ...OfferCardPackLayout[1],
          layout: [OfferCardPackLayout[1].layout[0]],
        },
      ],
      anchorOrigin: {
        vertical: 'center',
        horizontal: 'right',
        verticalOffset: 0,
        horizontalOffset: -1 * (EDIT_BUTTON_WIDTH / 2),
      },
    },
    price: {
      layout: [
        {
          ...OfferCardPackLayout[2],
          layout: [...OfferCardPackLayout[2].layout],
        },
      ],
      anchorOrigin: {
        vertical: 'center',
        horizontal: 'right',
        verticalOffset: 0,
        horizontalOffset: -1 * (EDIT_BUTTON_WIDTH / 2),
      },
    },
    badge: {
      layout: [
        {
          ...OfferCardPackLayout[3],
          layout: [...OfferCardPackLayout[3].layout],
        },
      ],
      anchorOrigin: {
        vertical: 'center',
        horizontal: 'right',
        verticalOffset: 0,
        horizontalOffset: -1 * (EDIT_BUTTON_WIDTH / 2),
      },
    },
    desc: {
      layout: [
        {
          ...OfferCardPackLayout[4],
          layout: [...OfferCardPackLayout[4].layout],
        },
      ],
      anchorOrigin: {
        vertical: 'center',
        horizontal: 'right',
        verticalOffset: 0,
        horizontalOffset: -1 * (EDIT_BUTTON_WIDTH / 2),
      },
    },
    activity: {
      layout: [
        {
          ...OfferCardPackLayout[5],
          layout: [...OfferCardPackLayout[5].layout],
        },
      ],
      anchorOrigin: {
        vertical: 'center',
        horizontal: 'right',
        verticalOffset: 0,
        horizontalOffset: -1 * (EDIT_BUTTON_WIDTH / 2),
      },
    },
    disclaimer1: {
      layout: [
        {
          ...OfferCardPackLayout[6],
          layout: [...OfferCardPackLayout[6].layout],
        },
      ],
      anchorOrigin: {
        vertical: 'center',
        horizontal: 'right',
        verticalOffset: 0,
        horizontalOffset: -1 * (EDIT_BUTTON_WIDTH / 2),
      },
    },
    disclaimer2: {
      layout: [
        {
          ...OfferCardPackLayout[7],
          layout: [...OfferCardPackLayout[7].layout],
        },
      ],
      anchorOrigin: {
        vertical: 'center',
        horizontal: 'right',
        verticalOffset: 0,
        horizontalOffset: -1 * (EDIT_BUTTON_WIDTH / 2),
      },
    },
    actions: {
      anchorOrigin: {
        vertical: 'top',
        horizontal: 'right',
        verticalOffset: EDIT_BUTTON_HEIGHT * 1.375,
        horizontalOffset: 4,
      },
    },
  },
  [RestModalTemplates.lco_popup]: {
    image: {
      layout: [LCOPopupLayout[0]],
      anchorOrigin: {
        vertical: 'center',
        horizontal: 'right',
        verticalOffset: 0,
        horizontalOffset: EDIT_BUTTON_WIDTH,
      },
    },
    title: {
      layout: [
        {
          ...LCOPopupLayout[1],
          layout: [LCOPopupLayout[1].layout[0]],
        },
      ],
      anchorOrigin: {
        vertical: 'center',
        horizontal: 'right',
        verticalOffset: 0,
        horizontalOffset: EDIT_BUTTON_WIDTH * 1.7,
      },
    },
    desc: {
      layout: [
        {
          ...LCOPopupLayout[1],
          layout: [LCOPopupLayout[1].layout[1]],
        },
      ],
      anchorOrigin: {
        vertical: 'center',
        horizontal: 'right',
        verticalOffset: 0,
        horizontalOffset: EDIT_BUTTON_WIDTH * 1.7,
      },
    },
    actions: {
      anchorOrigin: {
        vertical: 'center',
        horizontal: 'right',
        verticalOffset: 0,
        horizontalOffset: EDIT_BUTTON_WIDTH * 2.5,
      },
    },
  },
  [RestModalTemplates.modal_email_send]: {
    title: {
      layout: [
        {
          ...EmailSendModalLayout[0],
          layout: [EmailSendModalLayout[0].layout[0]],
        },
      ],
      anchorOrigin: {
        vertical: 'top',
        horizontal: 'right',
        verticalOffset: EDIT_BUTTON_HEIGHT * 1.125,
        horizontalOffset: EDIT_BUTTON_WIDTH / 2,
      },
    },
    desc: {
      layout: [
        {
          ...EmailSendModalLayout[0],
          layout: [EmailSendModalLayout[0].layout[1]],
        },
        EmailSendModalLayout[1],
      ],
      anchorOrigin: {
        vertical: 'top',
        horizontal: 'right',
        verticalOffset: EDIT_BUTTON_HEIGHT,
        horizontalOffset: EDIT_BUTTON_WIDTH / 2,
      },
    },
    actions: {
      anchorOrigin: {
        vertical: 'top',
        horizontal: 'right',
        verticalOffset: EDIT_BUTTON_HEIGHT * 1.375,
        horizontalOffset: EDIT_BUTTON_WIDTH / 2,
      },
    },
  },
  [RestModalTemplates.modal_email_success]: {
    title: {
      layout: [EmailSuccessModalLayout[0]],
      anchorOrigin: {
        vertical: 'top',
        horizontal: 'right',
        verticalOffset: EDIT_BUTTON_HEIGHT * 1.125,
        horizontalOffset: EDIT_BUTTON_WIDTH / 2,
      },
    },
    desc: {
      layout: [EmailSuccessModalLayout[1]],
      anchorOrigin: {
        vertical: 'top',
        horizontal: 'right',
        verticalOffset: EDIT_BUTTON_HEIGHT,
        horizontalOffset: EDIT_BUTTON_WIDTH / 2,
      },
    },
    actions: {
      anchorOrigin: {
        vertical: 'top',
        horizontal: 'right',
        verticalOffset: EDIT_BUTTON_HEIGHT * 1.375,
        horizontalOffset: EDIT_BUTTON_WIDTH / 2,
      },
    },
  },
  [RestModalTemplates.modal_email_structured]: {
    header: {
      layout: [
        {
          ...StructuredContentModalLayout[2],
        },
      ],
      anchorOrigin: {
        vertical: 'top',
        horizontal: 'right',
        verticalOffset: EDIT_BUTTON_HEIGHT * 1.25,
        horizontalOffset: EDIT_BUTTON_WIDTH / 2,
      },
    },
    title: {
      layout: [
        {
          ...StructuredContentModalLayout[0],
          layout: [StructuredContentModalLayout[0].layout[0]],
        },
      ],
      anchorOrigin: {
        vertical: 'top',
        horizontal: 'right',
        verticalOffset: EDIT_BUTTON_HEIGHT * 1.125,
        horizontalOffset: EDIT_BUTTON_WIDTH / 2,
      },
    },
    desc: {
      layout: [
        {
          ...StructuredContentModalLayout[0],
          layout: [StructuredContentModalLayout[0].layout[1]],
        },
      ],
      anchorOrigin: {
        vertical: 'top',
        horizontal: 'right',
        verticalOffset: EDIT_BUTTON_HEIGHT,
        horizontalOffset: EDIT_BUTTON_WIDTH / 2,
      },
    },
    opts: {
      layout: [StructuredContentModalLayout[1]],
      anchorOrigin: {
        vertical: 'top',
        horizontal: 'left',
        verticalOffset: EDIT_BUTTON_HEIGHT * 0.875,
        horizontalOffset: -1 * (EDIT_BUTTON_WIDTH / 2),
      },
    },
    actions: {
      anchorOrigin: {
        vertical: 'top',
        horizontal: 'right',
        verticalOffset: EDIT_BUTTON_HEIGHT * 1.375,
        horizontalOffset: EDIT_BUTTON_WIDTH / 2,
      },
    },
  },
  [RestModalTemplates.multi_cta_type_I]: {
    title: {
      layout: [
        {
          ...RestModalMultiCTATypeIEditCardLayout[0],
          layout: [RestModalMultiCTATypeIEditCardLayout[0].layout[0]],
        },
      ],
      anchorOrigin: {
        vertical: 'top',
        horizontal: 'right',
        verticalOffset: EDIT_BUTTON_HEIGHT * 1.125,
        horizontalOffset: EDIT_BUTTON_WIDTH / 2,
      },
    },
    desc: {
      layout: [
        {
          ...RestModalMultiCTATypeIEditCardLayout[0],
          layout: [RestModalMultiCTATypeIEditCardLayout[0].layout[1]],
        },
      ],
      anchorOrigin: {
        vertical: 'top',
        horizontal: 'right',
        verticalOffset: EDIT_BUTTON_HEIGHT,
        horizontalOffset: EDIT_BUTTON_WIDTH / 2,
      },
    },
    actions: {
      anchorOrigin: {
        vertical: 'center',
        horizontal: 'right',
        horizontalOffset: EDIT_BUTTON_WIDTH / 2,
      },
    },
  },
  [RestModalTemplates.multi_cta_type_II]: {
    title: {
      layout: [
        {
          ...RestModalMultiCTATypeIIEditCardLayout[0],
          layout: [RestModalMultiCTATypeIIEditCardLayout[0].layout[0]],
        },
      ],
      anchorOrigin: {
        vertical: 'top',
        horizontal: 'right',
        verticalOffset: EDIT_BUTTON_HEIGHT * 1.25,
        horizontalOffset: EDIT_BUTTON_WIDTH / 2,
      },
    },
    image: {
      layout: [
        {
          ...RestModalMultiCTATypeIIEditCardLayout[0],
          layout: [RestModalMultiCTATypeIIEditCardLayout[0].layout[1]],
        },
      ],
      anchorOrigin: {
        vertical: 'top',
        horizontal: 'right',
        verticalOffset: EDIT_BUTTON_HEIGHT + 4,
        horizontalOffset: -1 * (EDIT_BUTTON_WIDTH + 4),
      },
    },
    desc: {
      layout: [
        {
          ...RestModalMultiCTATypeIIEditCardLayout[0],
          layout: [RestModalMultiCTATypeIIEditCardLayout[0].layout[2]],
        },
      ],
      anchorOrigin: {
        vertical: 'top',
        horizontal: 'right',
        verticalOffset: EDIT_BUTTON_HEIGHT,
        horizontalOffset: EDIT_BUTTON_WIDTH / 2,
      },
    },
    actions: {
      anchorOrigin: {
        vertical: 'top',
        horizontal: 'right',
        verticalOffset: EDIT_BUTTON_HEIGHT * 1.375,
        horizontalOffset: EDIT_BUTTON_WIDTH / 2,
      },
    },
  },
  [RestLossAversionCardTemplates.la_one_up_image]: {
    title: {
      layout: [
        {
          ...OneUpImageLossAversionLayout[0],
          layout: [OneUpImageLossAversionLayout[0].layout[0]],
        },
      ],
      anchorOrigin: {
        vertical: 'top',
        horizontal: 'right',
        verticalOffset: EDIT_BUTTON_HEIGHT + 4 + 30,
        horizontalOffset: -1 * (EDIT_BUTTON_WIDTH + 4),
      },
    },
    image: {
      layout: [
        {
          ...OneUpImageLossAversionLayout[0],
          layout: [
            OneUpImageLossAversionLayout[0].layout[1],
            OneUpImageLossAversionLayout[0].layout[2],
          ],
        },
      ],
      anchorOrigin: {
        vertical: 'top',
        horizontal: 'right',
        verticalOffset: EDIT_BUTTON_HEIGHT + 4,
        horizontalOffset: -1 * (EDIT_BUTTON_WIDTH + 4),
      },
    },
  },
  [RestLossAversionCardTemplates.loss_simple_link]: {
    title: {
      layout: [
        {
          ...LossSimpleLinkLossAversionLayout[0],
          layout: [
            LossSimpleLinkLossAversionLayout[0].layout[0],
            LossSimpleLinkLossAversionLayout[0].layout[2],
          ],
        },
      ],
      anchorOrigin: {
        vertical: 'top',
        horizontal: 'right',
        verticalOffset: EDIT_BUTTON_HEIGHT + 10,
        horizontalOffset: EDIT_BUTTON_WIDTH / 2 + 5,
      },
    },
    image: {
      layout: [
        {
          ...LossSimpleLinkLossAversionLayout[0],
          layout: [
            LossSimpleLinkLossAversionLayout[0].layout[1],
            LossSimpleLinkLossAversionLayout[1].layout[1],
          ],
        },
      ],
      anchorOrigin: {
        vertical: 'top',
        horizontal: 'right',
      },
    },
    actions: {
      anchorOrigin: {
        vertical: 'top',
        horizontal: 'right',
        verticalOffset: EDIT_BUTTON_HEIGHT + 10,
        horizontalOffset: EDIT_BUTTON_WIDTH / 2 + 5,
      },
    },
  },
  [RestLossAversionCardTemplates.simple_list]: {
    title: {
      layout: [
        {
          ...LossSimpleListLossAversionLayout[0],
          layout: [LossSimpleListLossAversionLayout[0].layout[1]],
        },
      ],
      anchorOrigin: {
        vertical: 'top',
        horizontal: 'right',
        verticalOffset: EDIT_BUTTON_HEIGHT + 10,
        horizontalOffset: EDIT_BUTTON_WIDTH / 2 + 5,
      },
    },
    image: {
      layout: [
        {
          ...LossSimpleListLossAversionLayout[0],
          layout: [LossSimpleListLossAversionLayout[0].layout[0]],
        },
      ],
      anchorOrigin: {
        vertical: 'top',
        horizontal: 'right',
      },
    },
    btn: {
      anchorOrigin: {
        vertical: 'top',
        horizontal: 'right',
        verticalOffset: EDIT_BUTTON_HEIGHT + 10,
        horizontalOffset: EDIT_BUTTON_WIDTH / 2 + 5,
      },
    },
    partialInline: true,
  },
  [RestLossAversionCardTemplates.simple_chip]: {
    title: {
      layout: [
        {
          ...LossActivityLossAversionLayout[0],
          layout: [LossActivityLossAversionLayout[0].layout[0]],
        },
      ],
      anchorOrigin: {
        vertical: 'top',
        horizontal: 'right',
        verticalOffset: EDIT_BUTTON_HEIGHT + 10,
        horizontalOffset: EDIT_BUTTON_WIDTH / 2 + 5,
      },
    },
    btn: {
      anchorOrigin: {
        vertical: 'top',
        horizontal: 'right',
        verticalOffset: EDIT_BUTTON_HEIGHT + 10,
        horizontalOffset: EDIT_BUTTON_WIDTH / 2 + 5,
      },
    },
    partialInline: true,
  },
  [RestModalTemplates.final_offer]: {
    title: {
      layout: [
        {
          ...FinalOfferEditCardLayout[0],
          layout: [FinalOfferEditCardLayout[0].layout[0]],
        },
      ],
      anchorOrigin: {
        vertical: 'top',
        horizontal: 'right',
        verticalOffset: EDIT_BUTTON_HEIGHT * 1.125,
        horizontalOffset: EDIT_BUTTON_HEIGHT / 2,
      },
    },
    image: {
      layout: [
        {
          ...FinalOfferEditCardLayout[0],
          layout: [FinalOfferEditCardLayout[0].layout[1]],
        },
      ],
      anchorOrigin: {
        vertical: 'top',
        horizontal: 'right',
        verticalOffset: EDIT_BUTTON_HEIGHT + 4,
        horizontalOffset: -1 * (EDIT_BUTTON_WIDTH + 4),
      },
    },
    desc: {
      layout: [
        {
          ...FinalOfferEditCardLayout[0],
          layout: [FinalOfferEditCardLayout[0].layout[2]],
        },
      ],
      anchorOrigin: {
        vertical: 'top',
        horizontal: 'right',
        verticalOffset: EDIT_BUTTON_HEIGHT * 1.25,
        horizontalOffset: EDIT_BUTTON_HEIGHT / 2,
      },
    },
    actions: {
      anchorOrigin: {
        vertical: 'top',
        horizontal: 'right',
        verticalOffset: EDIT_BUTTON_HEIGHT * 1.375,
        horizontalOffset: EDIT_BUTTON_HEIGHT / 2,
      },
    },
  },
} as const;

export const InlineEditorsTemplates: (
  | RestModalTemplates
  | RestLossAversionCardTemplates
)[] = Object.keys(TemplateConfigs);

export const InlineEditors: ReactFCC<{
  card: RestModalCard;
  previewRef: RefObject<HTMLDivElement>;
}> = ({ card, previewRef }) => {
  const { id: cardId, template: templateName } = card;
  const prevCardId = usePrevious(cardId);
  const templateLayout = useMemo(() => getBaseLayout(card), [card]);
  const actionsLayout = useMemo(() => getActionLayout(card), [card]);

  const { offer } = useContext(EditOfferPageContext);
  const cardIdx = offer.cards.indexOf(card);
  const isNew = offer?.isNew;

  const refsContext = useContext(RefsContext);

  const nonNulRefs = useMemo(
    () =>
      Object.fromEntries(
        Object.entries(refsContext?.refs ?? {}).filter(([, ref]) =>
          isDefined(ref?.current)
        )
      ),
    [refsContext]
  );

  const [localRefs, setLocalRefs] = useState(refsContext?.refs ?? {});
  const [resetLocalRefs, setResetLocalRefs] = useState(true);
  const refsKeys = useMemo(
    () => Object.keys(refsContext?.refs ?? {}).join(''),
    [refsContext]
  );
  useEffect(() => setLocalRefs(refsContext?.refs ?? {}), [refsKeys]);
  useEffect(() => {
    if (resetLocalRefs) {
      setLocalRefs(refsContext?.refs ?? {});
      setResetLocalRefs(false);
    }
  }, [resetLocalRefs]);

  const repaintEditButtons = useCallback(() => {
    setResetLocalRefs(true);
  }, []);

  useEffect(() => {
    if (prevCardId === undefined) return;
    setTimeout(() => repaintEditButtons(), 50);
  }, [repaintEditButtons, cardId, templateName]);

  const handleRepaintEditButtonsEvent = () => {
    setTimeout(() => repaintEditButtons(), 50);
  };

  useEffect(() => {
    window.addEventListener('resize', repaintEditButtons);
    window.addEventListener(
      'repaintEditButtons',
      handleRepaintEditButtonsEvent
    );
    return () => {
      window.removeEventListener('resize', repaintEditButtons);
      window.removeEventListener(
        'repaintEditButtons',
        handleRepaintEditButtonsEvent
      );
    };
  }, []);

  const resizeObserver = useResizeObserver(() => {
    // console.log('resize observed');
    setResetLocalRefs(true);
  });

  useEffect(() => {
    resizeObserver?.observe(previewRef?.current);
    return () => resizeObserver?.disconnect();
  }, [previewRef, resizeObserver]);
  useEffect(() => {
    if (!resetLocalRefs) {
      setTimeout(() => {
        // console.log('observer disconnected');
        resizeObserver?.disconnect();
      }, 50);
    }
  }, [resetLocalRefs]);

  const isOfferPack = isOfferCardPack(offer);

  const getTemplateConfig = (template: RestModalTemplates, refName: string) => {
    let tConfig = TemplateConfigs[template];
    if (isOfferPack) {
      const refNameSplit = refName.split('-');
      const cardPackIndex =
        refNameSplit.length > 2 ? refNameSplit[refNameSplit.length - 2] : 0;
      if (refNameSplit[refNameSplit.length - 1] === 'price') {
        const vCardContent = _.get(offer, [
          'cards',
          cardIdx,
          'requires',
          'content_set',
          cardPackIndex,
        ]);
        if (vCardContent) {
          const ctaList = _.get(
            vCardContent,
            ['forwards', 'cta_details_list'],
            []
          );
          if (ctaList.length === 1) {
            tConfig = {
              ...tConfig,
              price: {
                layout: [
                  {
                    ...OfferCardPackLayout[8],
                    layout: [...OfferCardPackLayout[8].layout],
                  },
                ],
                anchorOrigin: {
                  vertical: 'center',
                  horizontal: 'right',
                  verticalOffset: 0,
                  horizontalOffset: -1 * (EDIT_BUTTON_WIDTH / 2),
                },
              },
            };
          }
        }
      }
    }
    return tConfig;
  };

  return (
    <>
      {Object.entries(localRefs).map(([refName, ref]) => {
        const el: HTMLElement | undefined = ref?.current;
        if (!el) return null;
        const name = refName.split('-').pop();

        const refNameSplit = refName.split('-');
        const cardPackIndex =
          refNameSplit.length > 2 ? refNameSplit[refNameSplit.length - 2] : 0;

        const isBtn = name.startsWith('btn');
        const isGenericActions = name === 'actions';
        const isAction = isGenericActions || isBtn;
        const title = isAction ? 'Action' : 'Editor';
        const templateConfig = getTemplateConfig(templateName, refName);
        if (!templateConfig) return null;
        const editorConfig: InlineEditorsConfig = templateConfig[name];
        const btnAnchorOrigin = {
          vertical: 'top',
          horizontal: 'right',
          verticalOffset: EDIT_BUTTON_HEIGHT * 1.375,
          horizontalOffset: -1 * (EDIT_BUTTON_HEIGHT / 2),
        };
        const anchorOrigin =
          editorConfig?.anchorOrigin ??
          templateConfig?.anchorOrigin ??
          btnAnchorOrigin;
        const vertOffset = anchorOrigin?.verticalOffset ?? 0;
        const horOffset = anchorOrigin?.horizontalOffset ?? 0;

        const getEditorLayout = () => {
          if (isBtn) {
            if (name === 'btn') {
              return getForwardsActionLayout(card);
            }
            const otherCtaIdx = parseInt(name.slice(3), 10);
            const genericActionsLayout = getGenericActionsLayout(card);
            if (genericActionsLayout.length)
              return [genericActionsLayout[otherCtaIdx]];
            const otherActionsLayout = getOtherActionLayout(card);
            if (otherActionsLayout?.layouts?.length) {
              return [
                {
                  key: `other_actions.${otherCtaIdx}`,
                  layout: otherActionsLayout.layouts[otherCtaIdx],
                },
              ];
            }
          }
          if (isAction) {
            return actionsLayout;
          }
          return undefined;
        };

        const btnLayout = getEditorLayout();

        const applyLayoutFilter = (layouts) => {
          return layouts.map((x) => ({
            ...x,
            layout: (x?.layout ?? []).filter(
              (l) =>
                !(
                  l.key === 'url' ||
                  l.key === 'action' ||
                  l.key === 'hideCancelAction' ||
                  l.key.join?.('.') === 'sync.message'
                )
            ),
          }));
          return layouts
            .filter((lo) => !(lo.key === 'other_actions' && cardIdx > 0))
            .map((lo) => {
              if (lo.key === 'forwards') {
                if ((cardIdx > 0 && lo.layout[0].key === 'action') || !isNew) {
                  return {
                    ...lo,
                    layout: _.drop(lo.layout, 1),
                  };
                }
              }
              return lo;
            });
        };

        const filteredBtnLayout = btnLayout
          ? applyLayoutFilter(btnLayout)
          : btnLayout;
        const editorLayout =
          filteredBtnLayout ?? editorConfig?.layout ?? templateLayout;

        const layout = prepareLayout(card, editorLayout);
        const initialParentpath = isOfferPack
          ? [
              'cards',
              offer.cards.indexOf(card),
              'requires',
              'content_set',
              cardPackIndex,
            ]
          : ['cards', offer.cards.indexOf(card), 'requires'];
        const editors = (
          <CardGenericLayoutEditor
            wrapInCollapsableContainer={false}
            layout={layout}
            initParentPath={initialParentpath}
          />
        );

        const buttonCss = css`
          position: absolute;
          line-height: 1rem;
          top: ${calcTop(el, anchorOrigin) + vertOffset}px;
          left: ${calcLeft(el, anchorOrigin) + horOffset}px;
          z-index: 10;
        `;

        if (isGenericActions) return null;

        return (
          <GenericInlineEditor
            title={title}
            key={refName}
            content={editors}
            onOpen={() => setResetLocalRefs(false)}
            onClose={() => setResetLocalRefs(true)}
            refresh={resetLocalRefs}
            customStyles={buttonCss}
          >
            <EditButton>{Icons.pencil}</EditButton>
          </GenericInlineEditor>
        );
      })}
    </>
  );
};
