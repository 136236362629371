import type ActionButtonIcons from '@bbkFunnel/components/modals/sections/action-button-helpers';
import type { RestAbstractCard } from '@bbkAdminUtils/api-client/rest-cards-types';
import type {
  OfferCategory,
  OfferCtaDiscountOption,
  OfferCtaSubcategory,
} from '@bbkAdminUtils/api-client/rest-offers-api';
import type { DestinationType } from '@bbkAdminRedux/rtkq/experience.slice';
import type { CardLevelTheme } from '@bbkFunnel/pages/theme';

export type RestModalPerson = {
  name: string;
  avatar?: string;
  title?: string;
  organization?: string;
  preposition?: string;
};

type ActionButtonIconsType =
  | React.ComponentProps<typeof ActionButtonIcons>['icon']
  | 'none';

export enum RestModalAction {
  accept_offer = 'accept_offer',
  confirm_cancel = 'confirm_cancel',
  dismiss_modal = 'dismiss_modal',
  intercom_chat = 'intercom_chat',
  link = 'link',
  cancel_with_link = 'cancel_with_link',
  modal_email = 'modal_email',
  send_email = 'send_email',
  direct_from_survey_cancel = 'direct_from_survey_cancel',
  modal_email_structured = 'modal_email_structured',

  accept_offer_with_confirmation = 'accept_offer_with_confirmation',
  accepted_la_offer = 'accepted_la_offer',
  accepted_modal_offer = 'accepted_modal_offer',
  show_code = 'show_code',
  nevermind = 'nevermind',
}

export const RestModalActionsAccepts = [
  RestModalAction.link,
  RestModalAction.accept_offer_with_confirmation,
  RestModalAction.modal_email,
  RestModalAction.modal_email_structured,
  RestModalAction.send_email,
];

export const RestModalActionCancels = [
  RestModalAction.cancel_with_link,
  RestModalAction.confirm_cancel,
];
export const RestModalActionOther = [
  ...RestModalActionCancels,
  RestModalAction.nevermind,
];

export const RestModalActionHumanString: Partial<
  Record<RestModalAction, string>
> = {
  [RestModalAction.intercom_chat]: 'Intercom chat',
  [RestModalAction.modal_email]: 'Compose email',
  [RestModalAction.modal_email_structured]: 'Drilldown',
  [RestModalAction.send_email]: 'Send email',
  [RestModalAction.accept_offer]: 'Accept offer',
  [RestModalAction.accept_offer_with_confirmation]:
    'Accept offer - show confirmation',
  [RestModalAction.link]: 'Link',
  [RestModalAction.cancel_with_link]: 'Cancel',
  [RestModalAction.confirm_cancel]: 'Confirm a cancel',
  [RestModalAction.show_code]: 'Show discount code',
  [RestModalAction.dismiss_modal]: 'Dismiss modal',
  [RestModalAction.nevermind]: 'Nevermind',
};

export type SyncConfig = {
  offer?: string;
  deflect?: boolean;
  accept?: boolean;
  message?: string;
  includeSurveyAnswers?: boolean;
  appendAnswersToHash?: boolean;
};

export type RestModalForwardsIntercomChat = {
  action: RestModalAction.intercom_chat;
  text: string;
  icon?: ActionButtonIconsType;
  initial_message?: string;
  sync: SyncConfig;
};

export type RestModalForwardsModalEmail = {
  action: RestModalAction.modal_email;
  text: string;
  modal: string;
  icon?: ActionButtonIconsType;
  sync: SyncConfig;
};

export type RestModalForwardsLink = {
  action: RestModalAction.link;
  text: string;
  cancelText?: string;
  subText?: string;
  url: string;
  icon?: ActionButtonIconsType;
  sync: SyncConfig;
};

export type RestModalForwardsCancelWithLink = {
  action: RestModalAction.cancel_with_link;
  text: string;
  cancelText?: string;
  subText?: string;
  url: string;
  icon?: ActionButtonIconsType;
  sync: SyncConfig;
};

export type RestModalForwardsNevermind = {
  action: RestModalAction.nevermind;
  text: string;
  url: string;
  icon?: ActionButtonIconsType;
  sync: SyncConfig;
};

export type RestModalForwardsAcceptOffer = {
  action: RestModalAction.accept_offer;
  text: string;
  icon?: ActionButtonIconsType;
  sync: SyncConfig;
};

export type RestModalForwardsSendEmail = {
  action: RestModalAction.send_email;
  text: string;
  url: string;
  modal: string;
  icon?: ActionButtonIconsType;
  sync: SyncConfig;
};

export type RestModalForwardsDismissModal = {
  action: RestModalAction.dismiss_modal;
  text: string;
  icon?: ActionButtonIconsType;
  sync: SyncConfig;
};
export type PauseOfferType = 'DELAY' | 'SKIP' | 'INDEFINITE'; //if billing processing
export enum CtaProcessingOption {
  billing = 'billing',
  webhook = 'webhook',
  email = 'email',
  url = 'url',
}

export type CtaDetails = {
  cta_id: string;
  processing_option: CtaProcessingOption;
  category: OfferCategory;
  sub_category?: OfferCtaSubcategory;

  text?: string;

  // Discount fields
  discount_option?: OfferCtaDiscountOption;
  amount?: number;
  currency?: string;
  coupon_id?: string; // if billing processing

  // Pause and extension field
  duration?: string;

  // plan change
  quantity?: number;
  plan_id?: string; // if billing processing
  pause_offer_type?: PauseOfferType;
};
// | RestModalForwardsIntercomChat
export type RestModalForwards = (
  | RestModalForwardsModalEmail
  | RestModalForwardsSendEmail
  | RestModalForwardsAcceptOffer
  | RestModalForwardsLink
  | RestModalForwardsCancelWithLink
  | RestModalForwardsNevermind
  | RestModalForwardsDismissModal
  | {
      type?: string;
      text?: string;
      subText?: string;
      action: RestModalAction; // TODO: currently action may contain name of the modal. We need to migrate those names to modal field
      modal?: string; // name of the modal
      icon?: ActionButtonIconsType;
      url: string;
      initial_message?: string;
      sync: SyncConfig;
    }
) & {
  url?: string;
  modal?: string;
  type?: string;
  validation: string;
  cta_details?: Partial<CtaDetails>;
  cta_details_list?: Partial<CtaDetails>[];
  destination_type?: DestinationType;
  subText?: string;
  hideCancelAction?: boolean;
  cancelText?: string;
  cancelIcon?: ActionButtonIconsType;
  disabled?: boolean;
  hide_action?: boolean;
};

export type RestModalInfo = {
  title: string;
  headline?: string;
  details?: string;
  offer?: string;
  cancelText?: string;
};

export type RestModalPrice = {
  current_price: string;
  new_price?: string;
  frequency: string;
  hide_price_section: boolean;
  hide_new_price?: boolean;
};

export type RestG2Rating = {
  rating: number;
  badge: string; // url
};

export type RestQuote = {
  text: string;
  avatar?: string;
  name: string;
  title: string;
};

export type LossQuote = {
  author: string;
  avatar?: string;
  position: string;
  title: string;
  quote: string;
};

export type LossActivity = {
  icon: string;
  text: string;
  unit: string;
  value: string;
  volume?: string;
};

export type RestOfferCode = {
  code: string;
  how_to: string[];
};

export enum RestModalTemplates {
  sixty_forty_column = 'sixty_forty_column',
  sixty_forty_column_action = 'sixty_forty_column_action',
  sixty_forty_column_code = 'sixty_forty_column_code',
  sixty_forty_column_twitter = 'sixty_forty_column_twitter',
  sixty_forty_column_g2 = 'sixty_forty_column_g2',
  fifty_fifty_column = 'fifty_fifty_column',
  seventy_thirty_row = 'seventy_thirty_row',
  video_row = 'video_row',
  speech_bubble_1 = 'speech_bubble_1',
  speech_bubble_2 = 'speech_bubble_2',
  forty_sixty_mini_modal_1 = 'forty_sixty_mini_modal_1',
  forty_sixty_mini_modal_2 = 'forty_sixty_mini_modal_2',
  forty_sixty_mini_modal_3 = 'forty_sixty_mini_modal_3',
  forty_sixty_mini_modal_4 = 'forty_sixty_mini_modal_4',
  intercom_chat_modal = 'intercom_chat_modal',
  modal_email_send = 'modal_email_send',
  modal_email_structured = 'modal_email_structured',
  modal_email_success = 'modal_email_success',
  modal_confirm_cancel = 'modal_confirm_cancel',
  final_offer = 'final_offer',
  multi_cta_type_I = 'multi_cta_type_I',
  multi_cta_type_II = 'multi_cta_type_II',
  lco_banner = 'lco_banner',
  lco_modal = 'lco_modal',
  lco_popup = 'lco_popup',
  offer_card_pack = 'offer_card_pack',
}

export enum RestModalTemplatesReadOnly {
  sixty_forty_column_action = 'sixty_forty_column_action',
  sixty_forty_column_code = 'sixty_forty_column_code',
  sixty_forty_column_twitter = 'sixty_forty_column_twitter',
  sixty_forty_column_g2 = 'sixty_forty_column_g2',
  fifty_fifty_column = 'fifty_fifty_column',
  seventy_thirty_row = 'seventy_thirty_row',
  speech_bubble_1 = 'speech_bubble_1',
  forty_sixty_mini_modal_3 = 'forty_sixty_mini_modal_3',
  forty_sixty_mini_modal_4 = 'forty_sixty_mini_modal_4',
  intercom_chat_modal = 'intercom_chat_modal',
  multi_cta_type_I = 'multi_cta_type_I',
  multi_cta_type_II = 'multi_cta_type_II',
}

type Provides = Record<string, unknown>;

export type RestModalRequires = {
  forwards: RestModalForwards;
  other_actions?: RestModalForwards[];
  generic_actions?: RestModalForwards[];
  includeOr?: boolean;
  includeOrText?: string;
  includeOtherTitle?: boolean;
  includeOtherTitleText?: string;
  backwards?: RestModalForwards;
};

export type RestAbstractModal = RestAbstractCard & {
  deploy: 'modal';
  template: RestModalTemplates;
  dismissable?: boolean;
  requires: RestModalRequires;
  style_overlay?: CardLevelTheme;
  provides: Provides;
  additional_properties?: {
    position?: string;
  };
};

export type OtherActionGenerics = {
  includeOr?: boolean;
  includeOrText?: string;
};

export type HeroImageV0 = {
  img: string;
};

export type HeroImageV1 = {
  show: 'image';
  url: string;
};

export type HeroImage = HeroImageV0 | HeroImageV1;

export type HeroVideo = {
  show: 'video';
  url: string;
};

export type HeroTwitter = {
  show: 'twitter_feed';
  title?: string;
  content: string[];
};

export type HeroQuote = {
  show: 'quote';
  title?: string;
  quote: RestQuote;
};

export type HeroQuotes = {
  show: 'quotes';
  title?: string;
  quotes: RestQuote[];
};

export type RestSixtyFortyColumnModal = RestAbstractModal & {
  template: RestModalTemplates.sixty_forty_column;
  requires: {
    info: RestModalInfo;
    hero?: HeroImage;
    person?: RestModalPerson;
  };
};

export type RestLCOBanner = RestAbstractModal & {
  template: RestModalTemplates.lco_banner;
  requires: {
    info: RestModalInfo;
    hero?: HeroImage;
    person?: RestModalPerson;
  };
};

export type RestLCOModal = RestAbstractModal & {
  template: RestModalTemplates.lco_modal;
  requires: {
    info: RestModalInfo;
    hero?: HeroImage;
    person?: RestModalPerson;
  };
};

export type RestLCOPopUp = RestAbstractModal & {
  template: RestModalTemplates.lco_popup;
  requires: {
    info: RestModalInfo;
    hero?: HeroImage;
    person?: RestModalPerson;
  };
};
export type RestOfferCardPack = RestAbstractModal & {
  template: RestModalTemplates.offer_card_pack;
  requires: {
    info: RestModalInfo;
    hero?: HeroImage;
    person?: RestModalPerson;
    content_set?: ModalContentSetItem[];
  };
};

export type ModalContentSetItem = RestModalRequires & {
  info: RestModalInfo & {
    description: string;
    hide_description?: boolean;
    disclaimer1: string;
    hide_disclaimer1: boolean;
    disclaimer2: string;
    hide_disclaimer2: boolean;
    hide_title?: boolean;
  };
  hero: HeroImage & {
    hide_image?: boolean;
  };
  price: RestModalPrice;
  badge: {
    title: string;
    hide_badge?: boolean;
  };
  hide_card?: boolean;
  section_order: Array<string>;
  activity: {
    item_list: Array<string>;
    hide_activity?: boolean;
    bullet_image?: string;
    bullet_icon?: string;
  };
};

export type RestSixtyFortyColumnActionModal = RestAbstractModal & {
  template: RestModalTemplates.sixty_forty_column_action;
  requires: {
    info: RestModalInfo;
    hero: HeroImage;
  };
} & {
  requires: {
    questions: {
      feedback?: {
        label: string;
        type: 'textarea';
      };
      plan_options?: {
        label: string;
        options: {
          name: string;
          key: string;
        }[];
        placeholder: string;
      };
      price_per_month?: {
        period_label?: string;
        placeholder?: string;
      };
    };
  };
  provides: {
    plan_options?: string;
    price_per_month?: string;
    feedback?: string;
  };
};

export type RestSixtyFortyColumnCodeModal = RestAbstractModal & {
  template: RestModalTemplates.sixty_forty_column_code;
  requires: {
    info: RestModalInfo;
    hero: HeroImage;
    offerCode: RestOfferCode;
    backwards: RestModalForwards;
  };
};

export type RestSixtyFortyColumnTwitterModal = RestAbstractModal & {
  template: RestModalTemplates.sixty_forty_column_twitter;
  requires: {
    info: RestModalInfo;
    hero: HeroTwitter;
  };
};

export type RestSixtyFortyColumnG2Modal = RestAbstractModal & {
  template: RestModalTemplates.sixty_forty_column_g2;
  requires: { info: RestModalInfo } & {
    hero: HeroQuotes;
    g2_rating: RestG2Rating;
  };
  provides: {
    feedback: string;
  };
};

export type RestFiftyFiftyColumnModal = RestAbstractModal & {
  template: RestModalTemplates.fifty_fifty_column;
  requires: {
    info: RestModalInfo;
    hero: HeroQuote;
  };
};

export type RestSeventyThirtyRowModal = RestAbstractModal & {
  template: RestModalTemplates.seventy_thirty_row;
  requires: {
    info: RestModalInfo;
    hero: {
      quotes: RestQuote[];
      // TODO: g2_rating is inside hero, where is in sixty_forty_column_g2 it's on the same level as hero
      g2_rating: RestG2Rating;
    };
    main_action?: {
      icon: string;
      type: 'button';
      text: string;
      action: 'intercom_chat';
      sync: SyncConfig;
    };
  };
  provides: {
    feedback: string;
  };
};

export type RestVideoRowModal = RestAbstractModal & {
  template: RestModalTemplates.video_row;
  requires: {
    info: RestModalInfo & {
      detail_heading: string;
    };
    hero: HeroVideo;
  };
};

export type RestSpeechBubble1Modal = RestAbstractModal & {
  template: RestModalTemplates.speech_bubble_1;
  requires: {
    info: RestModalInfo & {
      question?: string;
    };
    person: RestModalPerson;
  };
};

export type RestSpeechBubble2Modal = RestAbstractModal & {
  template: RestModalTemplates.speech_bubble_2;
  requires: {
    info: RestModalInfo;
    person: RestModalPerson;
  };
  readme?: string;
};

export type RestFortySixtyMiniModal1Modal = RestAbstractModal & {
  template: RestModalTemplates.forty_sixty_mini_modal_1;
  requires: {
    info: RestModalInfo;
    person?: RestModalPerson;
    hero?: HeroImage;
  };
};

export type RestFortySixtyMiniModal2Modal = RestAbstractModal & {
  template: RestModalTemplates.forty_sixty_mini_modal_2;
  requires: {
    info: RestModalInfo;
    person?: RestModalPerson;
    hero?: HeroImage;
  };
  provides: {
    feedback: string;
  };
};

export type RestAccountData = {
  account?: string;
  customer_since?: string;
};
export type RestFortySixtyMiniModal3Modal = Omit<
  RestAbstractModal,
  'requires'
> & {
  template: RestModalTemplates.forty_sixty_mini_modal_3;
  requires: Omit<RestModalRequires, 'backwards'> & {
    info: RestModalInfo & {
      account_data: RestAccountData;
      offer_details: string[];
      interest?: string;
      inline_disclaimer?: string;
      disclaimer?: string;
      offer_plea?: string;
    };
    person?: RestModalPerson;
    backwards: {
      type: 'link';
      text: string;
      action: 'link' | 'accept_offer';
      url: string;
      validation: string;
      modal: string;
      sync: SyncConfig;
    };
  };
};

export type RestFortySixtyMiniModal4Modal = RestAbstractModal & {
  template: RestModalTemplates.forty_sixty_mini_modal_4;
  requires: {
    info: RestModalInfo & {
      account_data: RestAccountData;
      importantTitle: string;
      important: string[];
      cta: string;
      phrase?: string;
      placeholder?: string;
    };
    person?: RestModalPerson;
    backwards: {
      type: 'email';
      text: string;
      action: 'modal_email';
      url: string;
      validation: string;
      modal: string;
      sync: SyncConfig;
    };
  };
};

export type RestModalEmailSendModal = RestAbstractModal & {
  template: RestModalTemplates.modal_email_send;
  requires: {
    info: {
      title: string;
      placeholder?: string;
    };
    message?: string;
    hideCancel?: boolean;
  };
};

export type RestModalEmailStructuredModal = RestAbstractModal & {
  template: RestModalTemplates.modal_email_structured;
  requires: {
    info: {
      title: string;
      placeholder?: string;
      optionsExplanation?: string;
      options: string[];
      allowOther: boolean;
    };
  };
};

export type RestModalEmailSuccessModal = RestAbstractModal & {
  template: RestModalTemplates.modal_email_success;
  requires: {
    info: RestModalInfo;
    message: string;
    success: {
      title: string;
      details: string;
    };
    hideReturnBtn?: boolean;
    hideCancel?: boolean;
    provides?: Provides;
  };
};

type RestModalAcceptOfferSuccess = RestModalEmailSuccessModal & {
  template: 'acceptOfferSuccess';
};

export type RestIntercomChatModal = RestAbstractModal & {
  name: 'intercom_chat';
  template: RestModalTemplates.intercom_chat_modal;
  info: {
    message: string;
  };
};

export type RestModalConfirmCancelModal = RestAbstractModal & {
  template: RestModalTemplates.modal_confirm_cancel;
  requires: {
    info: {
      title: string;
      terms?: string;
      cta?: string;
      phrase?: string;
      placeholder?: string;
    };
  };
};

export type GenericActions = RestModalForwards[];

export type RestModalFinalOffer = RestAbstractModal & {
  template: RestModalTemplates.final_offer;
  requires: {
    info: {
      title: string;
      details: string;
      image: string;
    };
    generic_actions: GenericActions;
  };
};

export type RestModalMultiCTATypeI = RestAbstractModal & {
  template: RestModalTemplates.multi_cta_type_I;
  requires: {
    info: {
      title: string;
      details: string;
    };
    generic_actions: GenericActions;
  };
};
export type RestModalMultiCTATypeII = RestAbstractModal & {
  template: RestModalTemplates.multi_cta_type_II;
  requires: {
    info: {
      title: string;
      details: string;
      image: string;
    };
    generic_actions: GenericActions;
  };
};

export type RestModalCard =
  | RestSixtyFortyColumnModal
  | RestSixtyFortyColumnActionModal
  | RestSixtyFortyColumnCodeModal
  | RestSixtyFortyColumnTwitterModal
  | RestSixtyFortyColumnG2Modal
  | RestFiftyFiftyColumnModal
  | RestSeventyThirtyRowModal
  | RestVideoRowModal
  | RestSpeechBubble1Modal
  | RestSpeechBubble2Modal
  | RestFortySixtyMiniModal1Modal
  | RestFortySixtyMiniModal2Modal
  | RestFortySixtyMiniModal3Modal
  | RestFortySixtyMiniModal4Modal
  | RestIntercomChatModal
  | RestModalEmailSendModal
  | RestModalEmailSuccessModal
  | RestModalConfirmCancelModal
  | RestModalFinalOffer
  | RestModalMultiCTATypeII
  | RestModalMultiCTATypeI
  | RestModalAcceptOfferSuccess
  | RestModalEmailStructuredModal
  | RestLCOBanner
  | RestLCOModal
  | RestLCOPopUp
  | RestOfferCardPack;
