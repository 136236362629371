// @ts-nocheck
/* eslint-disable react/button-has-type */
import { getDefaultActionContent } from '@bbkAdminComponents/experiences/offers/content/action-content';
import type {
  GenericActions,
  RestModalForwards,
} from '@bbkAdminUtils/api-client/rest-modals-cards-types';
import {
  RestModalAction,
  RestModalTemplates,
} from '@bbkAdminUtils/api-client/rest-modals-cards-types';
import {
  OfferCategory,
  type RestLossAversion,
  type RestOffer,
} from '@bbkAdminUtils/api-client/rest-offers-api';
import '../new-offer-page.scss';
import '../offers-management-page-styles.scss';
import { RestLossAversionCardTemplates } from '@bbkAdminUtils/api-client/rest-la-cards-types';

export type Action = {
  name: string;
  description: string;
  forwards?: RestModalForwards | null;
  generic_actions?: GenericActions;
  group: 'finalOffer' | 'standardOffer';
  actionKey: RestModalAction;
};

export const actions: Action[] = [
  {
    name: 'Link',
    description: `
      Make the call to action button a link to a 3rd party site,
      like a support article or another webpage.
    `,
    forwards: getDefaultActionContent(RestModalAction.link),
    group: 'standardOffer',
    actionKey: RestModalAction.link,
  },
  {
    name: 'Send email (instantly)',
    description: `
      <div>
        Notify an email address when a customer accepts this offer and
        confirm that to the user visually.
      </div>
      <div>
        Uses pre-filled content, hidden to the customer, and
        skips user input.
      </div>
    `,
    forwards: getDefaultActionContent(RestModalAction.send_email),
    group: 'standardOffer',
    actionKey: RestModalAction.send_email,
  },
  {
    name: 'Send email - user message',
    description: `
      Allow a customer to type a custom message and submit it to
      an email address (like a support address). Shows a confirmation message.
    `,
    forwards: getDefaultActionContent(RestModalAction.modal_email),
    group: 'standardOffer',
    actionKey: RestModalAction.modal_email,
  },
  {
    name: 'Send structured email',
    description: `
      Provide multiple choice options a customer can choose between and submit it to
      an email address (like a support address).
    `,
    forwards: getDefaultActionContent(RestModalAction.modal_email_structured),
    group: 'standardOffer',
    actionKey: RestModalAction.modal_email_structured,
  },
  {
    name: 'Accept offer - show confirmation',
    description: `
      User can accept an offer and be presented with a confirmation upon acceptance.
      They can be offered to return to the product from the confirmation.
    `,
    forwards: getDefaultActionContent(
      RestModalAction.accept_offer_with_confirmation
    ),
    group: 'standardOffer',
    actionKey: RestModalAction.accept_offer_with_confirmation,
  },
  {
    name: 'Cancel',
    description: `
      Allow patron to cancel directly from the offer. They will not be required confirm anything.
    `,
    forwards: getDefaultActionContent(RestModalAction.cancel_with_link),
    group: 'standardOffer',
    actionKey: RestModalAction.cancel_with_link,
  },
  {
    name: 'Dismiss',
    description: `
      Close the offer without taking any action.
    `,
    forwards: getDefaultActionContent(RestModalAction.dismiss_modal),
    group: 'standardOffer',
    actionKey: RestModalAction.dismiss_modal,
  },
];

export type Layout = {
  name: string;
  description: string;
  offer: RestLossAversion | RestOffer;
  group: 'finalOffer' | 'standardOffer';
  category: 'modal' | 'lossAversion';
  actionPicker?: boolean;
  autoSelectAction?: RestModalAction;
};
export const layouts: Layout[] = [
  {
    name: 'Image Card',
    description: 'Use this card to present large images in your Offer.',
    offer: {
      id: 'jxLgqNr8DK',
      display_name: 'General Feedback',
      internal_name: 'sixty_forty_column14ffaa.1278111302',
      type: 'general_feedback',
      category: 'customer_feedback',
      published_at: '2020-09-23T14:59:17.137Z',
      primary_card_name: 'sixty_forty_column1',
      cards: [
        {
          type: 'Card',
          id: '388159ff-fa2b-4c3f-82e3-5ec05ac7f864',
          name: 'sixty_forty_column1',
          version: '22213',
          deploy: 'modal',
          template: RestModalTemplates.sixty_forty_column,
          requires: {
            forwards: {
              text: 'Send message',
              action: RestModalAction.link,
              url: 'https://www.brightback.com',
              validation:
                'c19f6b5f3c38668a5b72cda1043c5744bd89fc2a114af6a28a8db694907197c2',
              sync: {},
              icon: 'none',
            },
            hero: {
              show: 'image',
              url: 'https://cdn.app.prod.brightback.com/content/immutable/companies/abhis_apple_shop/apps/072rQd9vmP/assets/876c27b3-b6f4-42e8-9f7f-c7a0f330975d.png',
            },
            info: {
              title: '<div>Help us Improve!&nbsp;</div>',
              details:
                '<div class="slate-p">We are always looking for ways to improve our product or service for future customers. Can you take a minute to share some feedback? </div>',
            },
          },
          provides: {},
        },
      ],
      placements: [],
      created_at: '2020-06-30T03:27:21.048Z',
      updated_at: '2020-06-30T05:28:10.868Z',
    },
    group: 'standardOffer',
    category: 'modal',
  },
  {
    name: 'Offer card pack',
    description: 'Use this card to present large images in your Offer.',
    offer: {
      display_name: 'Annual, Monthly, and Weekly plan',
      internal_name: 'offer_card_pack.1278111302',
      type: 'multi_action',
      category: 'multi_action',
      published_at: '2020-09-23T14:59:17.137Z',
      primary_card_name:
        'multi_cta.offer_card_pack.a905a907-3138-47aa-ac2a-2a0363bd07c6',
      cards: [
        {
          type: 'Card',
          id: 'a905a907-3138-47aa-ac2a-2a0363bd07c6',
          name: 'multi_cta.offer_card_pack.a905a907-3138-47aa-ac2a-2a0363bd07c6',
          version: '22213',
          deploy: 'exit_survey',
          template: RestModalTemplates.offer_card_pack,
          requires: {
            content_set: [
              {
                forwards: {
                  text: 'Upgrade to annual',
                  action: 'link',
                  url: 'https://www.brightback.com',
                  validation:
                    'c19f6b5f3c38668a5b72cda1043c5744bd89fc2a114af6a28a8db694907197c2',
                  sync: {},
                  icon: 'none',
                  cta_details_list: [
                    {
                      processing_option: 'url',
                      category: 'plan_change',
                    },
                    {
                      processing_option: 'url',
                      category: 'discount',
                    },
                  ],
                },
                hero: {
                  show: 'image',
                  url: 'https://cdn.app.prod.brightback.com/content/immutable/general/7020741a-37fb-4298-b0da-2313ac820fe9.png',
                },
                info: {
                  title: 'Annual plan',
                  description:
                    'You have been with Chargebee Retention for a while now and we value your loyalty to our product!',
                  disclaimer1: 'You can cancel anytime.',
                  disclaimer2:
                    'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
                },
                price: {
                  current_price: '$20',
                  new_price: '$15',
                  frequency: '/daily',
                  hide_price: false,
                },
                badge: {
                  title: '25% off',
                  badge_color: 'pink',
                },
                activity: {
                  item_list: [
                    'print edition',
                    'doorstep delivery',
                    'published Bi-monthly',
                  ],
                  bullet_image:
                    'https://cdn.app.prod.brightback.com/content/immutable/general/7b5e73e7-6173-4495-a49b-1361d707558a.png',
                },
              },
              {
                forwards: {
                  text: 'Upgrade to monthly',
                  action: 'link',
                  url: 'https://www.brightback.com',
                  validation:
                    'c19f6b5f3c38668a5b72cda1043c5744bd89fc2a114af6a28a8db694907197c2',
                  sync: {},
                  icon: 'none',
                  cta_details_list: [
                    {
                      processing_option: 'url',
                      category: 'plan_change',
                    },
                    {
                      processing_option: 'url',
                      category: 'discount',
                    },
                  ],
                },
                hero: {
                  show: 'image',
                  url: 'https://cdn.app.prod.brightback.com/content/immutable/general/5d553c81-62d7-4fb1-9296-4f8664fbc821.png',
                },
                info: {
                  title: 'Monthly plan',
                  description:
                    'You have been with Chargebee Retention for a while now and we value your loyalty to our product!',
                  disclaimer1: 'You can cancel anytime.',
                  disclaimer2:
                    'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
                },
                price: {
                  current_price: '$20',
                  new_price: '$15',
                  frequency: '/daily',
                  hide_price: false,
                },
                badge: {
                  title: '30% off',
                },
                activity: {
                  item_list: [
                    'Unlimited access, anytime, anywhere',
                    'Digital edition',
                    'Read on the go with the app',
                    'Access to archives',
                  ],
                  bullet_image:
                    'https://cdn.app.prod.brightback.com/content/immutable/general/7b5e73e7-6173-4495-a49b-1361d707558a.png',
                },
              },
              {
                forwards: {
                  text: 'Upgrade to weekly',
                  action: 'link',
                  url: 'https://www.brightback.com',
                  validation:
                    'c19f6b5f3c38668a5b72cda1043c5744bd89fc2a114af6a28a8db694907197c2',
                  sync: {},
                  icon: 'none',
                  cta_details_list: [
                    {
                      processing_option: 'url',
                      category: 'plan_change',
                    },
                    {
                      processing_option: 'url',
                      category: 'discount',
                    },
                  ],
                },
                hero: {
                  show: 'image',
                  url: 'https://cdn.app.prod.brightback.com/content/immutable/general/529a9f01-b464-4edf-bbee-564c1c44565f.png',
                },
                info: {
                  title: 'Weekly plan',
                  description:
                    'You have been with Chargebee Retention for a while now and we value your loyalty to our product!',
                  disclaimer1: 'You can cancel anytime.',
                  disclaimer2:
                    'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
                },
                price: {
                  current_price: '$20',
                  new_price: '$15',
                  frequency: '/daily',
                  hide_price: false,
                },
                badge: {
                  title: '20% off',
                },
                activity: {
                  item_list: [
                    'Subscribe to other magazines with DashStack',
                    'Great value deals',
                    'Yearly offers',
                    'Doorstep delivery',
                  ],
                  bullet_image:
                    'https://cdn.app.prod.brightback.com/content/immutable/general/7b5e73e7-6173-4495-a49b-1361d707558a.png',
                },
              },
            ],
          },
          provides: {},
          cards: [
            {
              requires: {
                forwards: {
                  cta_details: {},
                },
                other_actions: [
                  {
                    cta_details: {},
                  },
                ],
              },
            },
          ],
        },
      ],
      placements: [],
      created_at: '2020-06-30T03:27:21.048Z',
      updated_at: '2020-06-30T05:28:10.868Z',
      objective: 'UPSELL',
      presentation: 'card',
      isNew: true,
      groups: ['offer'],
    },
    group: 'standardOffer',
    category: 'modal',
  },
  {
    name: 'LCO Banner',
    description: 'Use this card to present offer as banner.',
    offer: {
      id: 'jxLgqNr8DK',
      display_name: 'General Feedback',
      internal_name: 'lco_banner.1278111302',
      type: 'general_feedback',
      category: 'customer_feedback',
      published_at: '2020-09-23T14:59:17.137Z',
      primary_card_name: 'lco_banner1',
      cards: [
        {
          type: 'Card',
          id: '388159ff-fa2b-4c3f-82e3-5ec05ac7f864',
          name: 'lco_banner1',
          version: '22213',
          deploy: 'modal',
          template: RestModalTemplates.lco_banner,
          requires: {
            forwards: {
              text: 'Send message',
              action: RestModalAction.link,
              url: 'https://www.brightback.com',
              validation:
                'c19f6b5f3c38668a5b72cda1043c5744bd89fc2a114af6a28a8db694907197c2',
              sync: {},
              icon: 'none',
            },
            hero: {
              show: 'image',
              url: 'https://cdn.app.prod.brightback.com/content/immutable/general/641271a1-a61b-4b7b-86af-9fc6c5a72912.png',
            },
            info: {
              title: '<div>Help us Improve!&nbsp;</div>',
              details:
                '<div class="slate-p">We are always looking for ways to improve our product or service for future customers. Can you take a minute to share some feedback? </div>',
            },
          },
          provides: {},
        },
      ],
      placements: [],
      created_at: '2020-06-30T03:27:21.048Z',
      updated_at: '2020-06-30T05:28:10.868Z',
    },
    group: 'standardOffer',
    category: 'modal',
  },
  {
    name: 'LCO Modal',
    description: 'Use this card to present LCO Offer modal.',
    offer: {
      id: 'jxLgqNr8DK',
      display_name: 'General Feedback',
      internal_name: 'lco_modal.1278111302',
      type: 'general_feedback',
      category: 'customer_feedback',
      published_at: '2020-09-23T14:59:17.137Z',
      primary_card_name: 'lco_modal1',
      cards: [
        {
          type: 'Card',
          id: '388159ff-fa2b-4c3f-82e3-5ec05ac7f864',
          name: 'lco_modal1',
          version: '22213',
          deploy: 'modal',
          template: RestModalTemplates.lco_modal,
          requires: {
            forwards: {
              text: 'Send message',
              action: RestModalAction.link,
              url: 'https://www.brightback.com',
              validation:
                'c19f6b5f3c38668a5b72cda1043c5744bd89fc2a114af6a28a8db694907197c2',
              sync: {},
              icon: 'none',
            },
            hero: {
              show: 'image',
              url: 'https://cdn.app.prod.brightback.com/content/immutable/companies/abhis_apple_shop/apps/072rQd9vmP/assets/876c27b3-b6f4-42e8-9f7f-c7a0f330975d.png',
            },
            info: {
              title: '<div>Help us Improve!&nbsp;</div>',
              details:
                '<div class="slate-p">We are always looking for ways to improve our product or service for future customers. Can you take a minute to share some feedback? </div>',
            },
          },
          provides: {},
        },
      ],
      placements: [],
      created_at: '2020-06-30T03:27:21.048Z',
      updated_at: '2020-06-30T05:28:10.868Z',
    },
    group: 'standardOffer',
    category: 'modal',
  },
  {
    name: 'LCO Popup',
    description: 'Use this card to present large images in your Offer.',
    offer: {
      id: 'jxLgqNr8DK',
      display_name: 'General Feedback',
      internal_name: 'lco_popup.1278111302',
      type: 'general_feedback',
      category: 'customer_feedback',
      published_at: '2020-09-23T14:59:17.137Z',
      primary_card_name: 'lco_popup1',
      cards: [
        {
          type: 'Card',
          id: '388159ff-fa2b-4c3f-82e3-5ec05ac7f864',
          name: 'lco_popup1',
          version: '22213',
          deploy: 'modal',
          template: RestModalTemplates.lco_popup,
          requires: {
            forwards: {
              text: 'Send message',
              action: RestModalAction.link,
              url: 'https://www.brightback.com',
              validation:
                'c19f6b5f3c38668a5b72cda1043c5744bd89fc2a114af6a28a8db694907197c2',
              sync: {},
              icon: 'none',
            },
            hero: {
              show: 'image',
              url: 'https://cdn.app.prod.brightback.com/content/immutable/companies/abhis_apple_shop/apps/072rQd9vmP/assets/876c27b3-b6f4-42e8-9f7f-c7a0f330975d.png',
            },
            info: {
              title: '<div>Help us Improve!&nbsp;</div>',
              details:
                '<div class="slate-p">We are always looking for ways to improve our product or service for future customers. Can you take a minute to share some feedback? </div>',
            },
          },
          provides: {},
        },
      ],
      placements: [],
      created_at: '2020-06-30T03:27:21.048Z',
      updated_at: '2020-06-30T05:28:10.868Z',
    },
    group: 'standardOffer',
    category: 'modal',
  },
  {
    name: 'Message Card One',
    description:
      'Use this card when you are trying to get the customer to engage with you either via chat, schedule time, or send email.',
    offer: {
      id: 'Ka6ywqxg57',
      display_name: '25% Discount',
      internal_name: 'video_paused_skipped64fa22.57660808',
      type: 'dollar',
      category: 'discount',
      published_at: '2020-08-10T17:35:52.704Z',
      primary_card_name: 'modal_video_paused_skipped_c',
      cards: [
        {
          type: 'Card',
          id: 'd3cc69b3-0024-4715-8515-44c8290e923',
          name: 'modal_video_paused_skipped_c',
          version: '22212',
          deploy: 'modal',
          template: RestModalTemplates.speech_bubble_2,
          requires: {
            forwards: {
              text: 'Help Jen Improve',
              action: 'modal_email',
              modal:
                'customer_service_feedback.modal_email_send.e92c943f-e263-4534-a3b1-fc03acb2cca8',
              validation:
                'c19f6b5f3c38668a5b72cda1043c5744bd89fc2a114af6a28a8db694907197c2',
              sync: {},
              icon: 'none',
            },
            person: {
              avatar:
                'https://cdn.app.prod.brightback.com/content/immutable/general/6f7037ba-7ad6-4648-ae75-c22bb5976675.jpg',
              name: 'Elyse',
              title: 'Product Manager',
              organization: 'Chargebee Retention',
              preposition: '@',
            },
            info: {
              title: '<div>Help me Improve my Service!</div>',
              details:
                '<div class="slate-p"> We pride ourselves on exceptional customer service and would appreciate any feedback on how we can improve!</div>',
            },
          },
          provides: {},
        },
      ],
      placements: [
        {
          funnel_id: 30,
          vanity_reason_id: 1784,
          content_id: 'Ka6ywqxg57',
          published_at: '2020-06-30T15:54:15.100Z',
        },
      ],
      created_at: '2020-06-30T03:27:20.949Z',
      updated_at: '2020-06-30T05:22:39.835Z',
    },
    group: 'standardOffer',
    category: 'modal',
  },
  {
    name: 'Message Card Two',
    description:
      'Similar to Message Card One, this card is used to present a message from an employee or key stakeholder along with the offer.',
    offer: {
      id: 'W47gZVZgPm',
      display_name: 'Company Feedback',
      internal_name: 'forty_sixty_mini_140965c.913292608',
      type: 'customer_service',
      category: 'customer_feedback',
      published_at: '2020-06-30T05:34:24.616Z',
      primary_card_name: 'forty_sixty_mini_modal_14',
      cards: [
        {
          type: 'Card',
          id: '2ba7f5f4-abf8-4f90-85de-2d3c850d1257',
          name: 'forty_sixty_mini_modal_14',
          version: '22216',
          deploy: 'modal',
          template: RestModalTemplates.forty_sixty_mini_modal_1,
          requires: {
            forwards: {
              text: 'Send message',
              action: 'modal_email',
              modal:
                'sorry_message.modal_email_send.fce81fae-f23c-4a1b-9905-61243cb77124',
              validation:
                'c19f6b5f3c38668a5b72cda1043c5744bd89fc2a114af6a28a8db694907197c2',
              sync: {},
              icon: 'bbk-esi-message',
            },
            person: {
              avatar:
                'https://cdn.app.prod.brightback.com/content/immutable/general/4745b2a6-bdfd-4780-8375-bd7a6a8f760e.jpg',
              name: 'Devin',
              title: 'Customer Success',
              organization: 'Chargebee Retention',
              preposition: '@',
            },
            info: {
              title: '<div>Help Us Improve</div>',
              details:
                '<div class="slate-p">We are sorry to hear you did not have a good experience! As CEO, I take this personally. Please provide feedback to help us improve. </div>',
            },
          },
          provides: {},
        },
      ],
      placements: [
        {
          funnel_id: 30,
          vanity_reason_id: 1764,
          content_id: 'W47gZVZgPm',
          published_at: '2020-06-30T15:54:15.100Z',
        },
      ],
      created_at: '2020-06-30T03:27:20.362Z',
      updated_at: '2020-06-30T05:33:10.420Z',
    },
    group: 'standardOffer',
    category: 'modal',
  },
  {
    name: 'Employee Card',
    description:
      'Use this card to present an Offer message from one of your employees such as an Account Manager, Product Manager or CEO.',
    offer: {
      id: '6aZyDPwyq3',
      display_name: 'Roadmap Card',
      internal_name: 'too_expensive9d167.409816330',
      type: 'schedule_time',
      category: 'support',
      published_at: '2020-06-30T05:43:46.643Z',
      primary_card_name: 'modal_too_expensive',
      cards: [
        {
          type: 'Card',
          id: 'e6ec86b6-af82-48f8-b286-ae45ac02fe5d',
          name: 'modal_too_expensive',
          version: '22215',
          deploy: 'modal',
          template: RestModalTemplates.forty_sixty_mini_modal_2,
          requires: {
            forwards: {
              type: 'composeEmail',
              text: 'Book Training',
              action: 'modal_email',
              sync: {
                form_submit: true,
              },
              icon: 'none',
            },
            person: {
              avatar:
                'https://cdn.app.prod.brightback.com/content/immutable/general/4745b2a6-bdfd-4780-8375-bd7a6a8f760e.jpg',
              name: 'Devin',
              title: 'Customer Success',
              organization: 'Chargebee Retention',
              preposition: '@',
            },
            info: {
              title: '<div class="slate-p">Free Training Session</div>',
              details:
                '<div class="slate-p">We have a free training session you can attend to learn how to use our product! </div>',
            },
          },
          provides: {},
        },
      ],
      placements: [],
      created_at: '2020-06-30T03:27:20.818Z',
      updated_at: '2020-06-30T05:43:46.636Z',
    },
    group: 'standardOffer',
    category: 'modal',
  },
  {
    name: 'Multi-CTA Type I',
    description:
      '<p>A card that lets you use multiple call-to-actions, allowing you to present many action options at once.</p><br /><p>',
    offer: {
      id: 'W47gZVZgPm',
      display_name: 'Pause your subscription',
      internal_name: 'pause_your_sub.913292608',
      type: 'customer_service',
      category: 'customer_feedback',
      published_at: '2020-06-30T05:34:24.616Z',
      primary_card_name: 'multi_cta_type_1',
      cards: [
        {
          type: 'Card',
          id: '2ba7f5f4-abf8-4f90-85de-2d3c850d1257',
          name: 'multi_cta_type_1',
          version: '22216',
          deploy: 'modal',
          template: RestModalTemplates.multi_cta_type_I,
          requires: {
            generic_actions: [
              {
                text: 'Pause for 1 week',
                action: RestModalAction.link,
                url: 'https://www.brightback.com?pause_for_1_week',
                validation: '123',
                sync: {},
              },
              {
                text: 'Pause for 2 weeks',
                action: RestModalAction.link,
                url: 'https://www.brightback.com?pause_for_2_weeks',
                validation: '123',
                sync: {},
              },
              {
                text: 'Pause for 1 month',
                action: RestModalAction.link,
                url: 'https://www.brightback.com?pause_for_1_month',
                validation: '123',
                sync: {},
              },
            ],
            info: {
              title: "<div>Let's pause your subscription</div>",
              details:
                '<div class="slate-p">We are sorry to hear you did not have a good experience! As CEO, I take this personally. Please provide feedback to help us improve. </div>',
            },
          },
          provides: {},
        },
      ],
      placements: [],
      created_at: '2020-06-30T03:27:20.362Z',
      updated_at: '2020-06-30T05:33:10.420Z',
    },
    actionPicker: true,
    group: 'standardOffer',
    category: 'modal',
  },
  {
    name: 'Multi-CTA Type II',
    description:
      '<p>A card that lets you use multiple call-to-actions and an image, allowing you to present many action options at once.</p><br /><p>',
    offer: {
      id: 'W47gZVZgPm',
      display_name: 'Pause your subscription',
      internal_name: 'pause_your_sub.913292608',
      type: 'customer_service',
      category: 'customer_feedback',
      published_at: '2020-06-30T05:34:24.616Z',
      primary_card_name: 'multi_cta_type_2',
      cards: [
        {
          type: 'Card',
          id: '2ba7f5f4-abf8-4f90-85de-2d3c850d1257',
          name: 'multi_cta_type_2',
          version: '22216',
          deploy: 'modal',
          template: RestModalTemplates.multi_cta_type_II,
          requires: {
            generic_actions: [
              {
                text: 'Pause for 1 week',
                action: RestModalAction.link,
                url: 'https://www.brightback.com?pause_for_1_week',
                validation: '123',
                sync: {},
              },
              {
                text: 'Pause for 2 weeks',
                action: RestModalAction.link,
                url: 'https://www.brightback.com?pause_for_2_weeks',
                validation: '123',
                sync: {},
              },
              {
                text: 'Pause for 1 month',
                action: RestModalAction.link,
                url: 'https://www.brightback.com?pause_for_1_month',
                validation: '123',
                sync: {},
              },
            ],
            info: {
              title: "<div>Let's pause your subscription</div>",
              details:
                '<div class="slate-p">We are sorry to hear you did not have a good experience! As CEO, I take this personally. Please provide feedback to help us improve. </div>',
              image:
                'https://cdn.app.prod.brightback.com/content/immutable/companies/abhis_apple_shop/apps/072rQd9vmP/assets/876c27b3-b6f4-42e8-9f7f-c7a0f330975d.png',
            },
          },
          provides: {},
        },
      ],
      placements: [],
      created_at: '2020-06-30T03:27:20.362Z',
      updated_at: '2020-06-30T05:33:10.420Z',
    },
    actionPicker: true,
    group: 'standardOffer',
    category: 'modal',
  },
  {
    name: 'Final offer',
    description:
      'Presented to a person as a final step before their cancel is complete.',
    offer: {
      id: 'L5B8QjOyJ5',
      display_name: 'Final Offer',
      internal_name: 'final_offer1183.213423929',
      type: 'discount',
      category: 'discount',
      published_at: '2020-08-10T17:37:07.385Z',
      primary_card_name: 'final_offer',
      cards: [
        {
          type: 'Card',
          id: 'e48b0ab5-4bf8-435b-b4bd-999554a1c06c',
          name: 'final_offer',
          version: '22257',
          deploy: 'modal',
          template: RestModalTemplates.final_offer,
          requires: {
            generic_actions: [
              {
                text: 'Accept offer',
                action: RestModalAction.link,
                url: 'https://www.brightback.com',
                validation:
                  'c9397036caf54d02a74e92f8b73da525de41119ea874155c4cfc05fdc3817386',
                sync: {},
              },
              {
                text: 'Decline and cancel immediately',
                action: RestModalAction.cancel_with_link,
                url: 'https://www.brightback.com?cancel',
                validation:
                  'c9397036caf54d02a74e92f8b73da525de41119ea874155c4cfc05fdc3817386',
                sync: {},
              },
            ],
            info: {
              title: "Here's our best offer - shh, don't tell anyone!",
              details:
                "We would really hate to lose you as a customer. That's why we'd like to offer you a full SIX MONTHS of our Gold plan. This offer is only valid for you, and will never be available again.",
              image:
                'https://cdn.app.prod.brightback.com/content/immutable/companies/abhis_apple_shop/apps/072rQd9vmP/assets/876c27b3-b6f4-42e8-9f7f-c7a0f330975d.png',
            },
          },
          provides: {},
        },
      ],
      placements: [],
      created_at: '2020-06-30T03:27:20.651Z',
      updated_at: '2020-06-30T04:08:28.699Z',
    },
    group: 'finalOffer',
    category: 'modal',
  },
  {
    name: 'Drilldown',
    description:
      '<p>Create options to allow a customer to provide you feedback.</p><p>&nbsp;Uses a pre-selected action, will skip directly to edit page.</p>',
    offer: {
      id: 'L5B8QjOyJ5',
      display_name: 'Drilldown',
      internal_name: 'modal_email_structured1183.213423929',
      type: 'discount',
      category: 'discount',
      published_at: '2020-08-10T17:37:07.385Z',
      primary_card_name: 'modal_email_structured',
      cards: [
        {
          type: 'Card',
          id: 'e48b0ab5-4bf8-435b-b4bd-999554a1c06c',
          name: 'modal_email_structured',
          version: '22257',
          deploy: 'modal',
          template: RestModalTemplates.modal_email_structured,
          requires: {
            info: {
              title: 'Send message',
              placeholder: 'Start typing...',
              optionsExplanation: 'Please choose one of the following options',
              options: ['First option', 'Second option', 'Third option'],
              allowOther: true,
            },
            forwards: {
              text: 'Send message',
              action: RestModalAction.send_email, // DO NOT CHANGE. USED TO FIRE EMAIL
              url: '',
              modal: 'modal_email_success_url', // SET THIS TO THE NEXT MODAL
              sync: {},
            },
          },
          provides: {},
        },
      ],
      placements: [],
      created_at: '2020-06-30T03:27:20.651Z',
      updated_at: '2020-06-30T04:08:28.699Z',
    },
    group: 'standard',
    category: 'modal',
    autoSelectAction: RestModalAction.send_email,
  },
  {
    name: 'Drilldown (with link)',
    description:
      '<p>Create options to allow a customer to provide you feedback.</p><p>&nbsp;Uses a url redirect to route the user to a URL of your choosing once they select an option</p>',
    offer: {
      id: 'L5B8QjOyJ5',
      display_name: 'Drilldown',
      internal_name: 'modal_email_structured1183.213423929',
      type: 'discount',
      category: 'discount',
      published_at: '2020-08-10T17:37:07.385Z',
      primary_card_name: 'modal_email_structured',
      cards: [
        {
          type: 'Card',
          id: 'e48b0ab5-4bf8-435b-b4bd-999554a1c06c',
          name: 'modal_email_structured',
          version: '22257',
          deploy: 'modal',
          template: RestModalTemplates.modal_email_structured,
          requires: {
            info: {
              title: 'Send message',
              placeholder: 'Start typing...',
              optionsExplanation: 'Please choose one of the following options',
              options: ['First option', 'Second option', 'Third option'],
              allowOther: true,
            },
            forwards: {
              text: 'Accept offer',
              action: RestModalAction.link,
              url: 'https://www.brightback.com',
              validation:
                'c9397036caf54d02a74e92f8b73da525de41119ea874155c4cfc05fdc3817386',
              sync: {},
            },
          },
          provides: {},
        },
      ],
      placements: [],
      created_at: '2020-06-30T03:27:20.651Z',
      updated_at: '2020-06-30T04:08:28.699Z',
    },
    group: 'standard',
    category: 'modal',
    autoSelectAction: RestModalAction.link,
  },
  {
    name: 'Video Modal',
    description: 'Use this card when you have a video to present in the Offer.',
    offer: {
      id: 'L5B8QjOyJ4',
      display_name: 'Getting Started Video',
      internal_name: 'video_row65de6.618671464',
      type: 'chat',
      category: 'support',
      published_at: '2020-08-10T17:37:07.385Z',
      primary_card_name: 'video_row',
      cards: [
        {
          type: 'Card',
          id: 'e48b0ab5-4bf8-435b-b4bd-999554a1c06c',
          name: 'video_row',
          version: '22257',
          deploy: 'modal',
          template: RestModalTemplates.video_row,
          requires: {
            forwards: {
              type: 'button',
              text: 'Return to Chargebee Retention',
              action: 'link',
              url: 'https://www.brightback.com',
              validation:
                '80a8abcac98decda5d0deae8116b21bb590b2e95ab5b0315bd65c11254dabcaa',
              sync: {
                form_submit: true,
              },
            },
            hero: {
              show: 'video',
              url: 'https://fast.wistia.net/embed/iframe/ki5p6ljpx0',
            },
            info: {
              title: '<div class="slate-p">Getting Started Video</div>',
              details:
                '<div class="slate-p">Check out our Getting Started Video to learn how you can make the most of Chargebee Retention! </div>',
            },
          },
          provides: {},
        },
      ],
      placements: [],
      created_at: '2020-06-30T03:27:20.651Z',
      updated_at: '2020-06-30T04:08:28.699Z',
    },
    group: 'standardOffer',
    category: 'modal',
  },
  {
    name: 'Loss Aversion CTA Card',
    description:
      'Use this card when you have an offer you want to present in Loss Aversion card. <div>NOTE: this will only work in the Loss Aversion Placement.</div>',
    offer: {
      id: 'YDx8rmNgXZ',
      display_name: 'Loss Aversion - Chat Now',
      internal_name: 'simple_simple_link59e660.1906020205',
      type: 'chat',
      category: 'support',
      published_at: '2020-08-10T17:37:15.633Z',
      primary_card_name: 'simple_simple_link5',
      cards: [
        {
          type: 'Card',
          id: '435ecc32-19ad-453a-9196-5261fc35db89',
          name: 'simple_simple_link5',
          version: '19496',
          deploy: 'exit_survey',
          template: RestLossAversionCardTemplates.loss_simple_link,
          requires: {
            title: '<div>Chat with a Retention Expert</div>',
            forwards: {
              type: 'button',
              text: 'Chat Now',
              action: 'link',
              url: 'https://www.brightback.com',
              validation:
                '80a8abcac98decda5d0deae8116b21bb590b2e95ab5b0315bd65c11254dabcaa',
              sync: {
                form_submit: false,
              },
            },
            image:
              'https://cdn.app.prod.brightback.com/content/immutable/companies/default/apps/lmQoGWoKNV/assets/9fc4bc82-f61f-45b4-ac29-6bed8e97bb7e',
            copy: '<div>Our Retention Experts are here to help you get Chargebee Retention setup to meet your needs!</div>',
            vertical: false,
          },
          provides: {},
        },
      ],
      placements: [],
      created_at: '2020-06-30T03:27:20.140Z',
      updated_at: '2020-06-30T05:34:54.501Z',
    },
    group: 'standardOffer',
    category: 'lossAversion',
  },
  {
    name: 'Vertical Loss Aversion CTA Card',
    description:
      'Use this card when you have an offer you want to present in Loss Aversion card. <div>NOTE: this will only work in the Loss Aversion Placement.</div>',
    offer: {
      id: 'wWlWoG4lRM',
      active: true,
      display_name: 'Vertical - LA Offer',
      internal_name: 'vertical_la_offer',
      type: 'precent',
      category: 'discount',
      published_at: '2022-06-07T22:11:26.908Z',
      primary_card_name:
        'vertical_la_offer.loss_simple_link.b16f7848-2fbe-427d-a276-a60cefbb19f3',
      cards: [
        {
          type: 'Card',
          id: 'b16f7848-2fbe-427d-a276-a60cefbb19f3',
          name: 'vertical_la_offer.loss_simple_link.b16f7848-2fbe-427d-a276-a60cefbb19f3',
          version: '81251',
          deploy: 'exit_survey',
          template: RestLossAversionCardTemplates.loss_simple_link,
          requires: {
            title: '<div class="slate-p">Chat with a Retention Expert</div>',
            forwards: {
              type: 'button',
              text: 'Apply 25% Discount',
              action: 'link',
              url: 'https://www.brightback.com',
              validation:
                '80a8abcac98decda5d0deae8116b21bb590b2e95ab5b0315bd65c11254dabcaa',
              sync: {},
            },
            image:
              'https://cdn.app.prod.brightback.com/content/immutable/companies/default/apps/lmQoGWoKNV/assets/73eaa9ce-2196-4e3c-a287-a307e8b4d0f7.jpg',
            vertical: true,
            copy: '<div class="slate-p">Schedule time with a Retention Expert to go through your setup and we will give you a 25% discount off your next month.</div>',
            info: {
              title:
                '<div class="slate-p">How about x% off your next month? </div>',
              details:
                '<div class="slate-p">You have been with Chargebee Retention for a while now and we value your loyalty to our product!</div>',
            },
          },
          provides: {},
        },
      ],
      placements: [],
      created_at: '2022-06-07T22:11:15.605Z',
      updated_at: '2022-06-07T22:11:15.605Z',
    },
    group: 'standardOffer',
    category: 'lossAversion',
  },
  {
    name: 'Loss Aversion CTA Image Card',
    description:
      'Use this card when you have an offer you want to present in Loss Aversion card where one clicks on the image to activate the offer. <div>NOTE: this will only work in the Loss Aversion Placement.</div>',
    offer: {
      id: '3L85EDb821',
      display_name: 'Loss Aversion - Image offer',
      internal_name: 'loss_aversion_image_offer90940.664824624',
      type: 'content',
      category: 'support',
      published_at: '2020-11-24T13:23:19.463Z',
      primary_card_name: 'loss_aversion_image_offer',
      cards: [
        {
          type: 'Card',
          id: '989c89a9-65d3-465e-8033-66f7de1b2bf4',
          name: 'loss_aversion_image_offer',
          version: '24748',
          deploy: 'exit_survey',
          template: RestLossAversionCardTemplates.la_one_up_image,
          requires: {
            title: 'Here’s what you’ll miss',
            forwards: {
              type: 'link',
              text: '',
              action: 'link',
              url: 'https://www.brightback.com/',
              validation:
                '3d755c3bb5b602de3aeeb07079bea7a7cbc99d88ea7bb535a7d6085df6c34fc8',
              sync: {
                form_submit: false,
              },
            },
            image:
              'https://cdn.app.prod.brightback.com/content/immutable/general/e9f4ff0e-40b3-4971-ad7b-e5de2e56a6c5.jpg',
          },
          provides: {},
        },
      ],
      placements: [],
      created_at: '2020-06-30T03:27:20.140Z',
      updated_at: '2020-06-30T05:34:54.501Z',
    },
    group: 'standardOffer',
    category: 'lossAversion',
  },
  {
    name: 'Loss Aversion Simple List',
    description:
      'Use this card when you have an offer you want to present in Loss Aversion card. <div>NOTE: this will only work in the Loss Aversion Placement.</div>',
    offer: {
      id: 'YDx8rmNgXZ',
      display_name: 'Loss Aversion Simple List',
      internal_name: 'simple_simple_list59e660.1906020205',
      category: OfferCategory.other,
      type: 'other',
      published_at: '2023-11-29T00:00:00.000Z',
      primary_card_name: 'simple_simple_list5',
      cards: [
        {
          type: 'Card',
          id: '435ecc32-19ad-453a-9196-5261fc35db89',
          name: 'simple_simple_list5',
          version: '19496',
          deploy: 'exit_survey',
          template: RestLossAversionCardTemplates.simple_list,
          requires: {
            title: "<div>Here's what you'll lose</div>",
            items: [
              'Cancel Page',
              'Revenue Dash',
              'Cancel Insights',
              'Testing & Targeting',
              'Trends Reports',
              'Offer Library',
            ],
            forwards: {
              type: 'button',
              text: 'Nevermind',
              action: RestModalAction.nevermind,
              url: 'https://www.brightback.com',
              validation:
                '80a8abcac98decda5d0deae8116b21bb590b2e95ab5b0315bd65c11254dabcaa',
              sync: {
                form_submit: true,
              },
            },
            image:
              'https://cdn.app.prod.brightback.com/content/immutable/companies/default/apps/lmQoGWoKNV/assets/9fc4bc82-f61f-45b4-ac29-6bed8e97bb7e',
            copy: '<div>Our Retention Experts are here to help you get Chargebee Retention setup to meet your needs!</div>',
          },
          provides: {},
        },
      ],
      placements: [],
      created_at: '2023-11-29T00:00:00.000Z',
      updated_at: '2023-11-29T00:00:00.000Z',
    },
    group: 'standardOffer',
    category: 'lossAversion',
  },
  {
    name: 'Loss Aversion Activities',
    description:
      'Use this card when you have an offer you want to present in Loss Aversion card. <div>NOTE: this will only work in the Loss Aversion Placement.</div>',
    offer: {
      id: 'YDx8rmNgXZ',
      display_name: 'Loss Aversion Activities',
      internal_name: 'simple_simple_chip59e660.1906020205',
      category: OfferCategory.other,
      type: 'other',
      published_at: '2023-11-29T00:00:00.000Z',
      primary_card_name: 'simple_simple_chip5',
      cards: [
        {
          type: 'Card',
          id: '435ecc32-19ad-453a-9196-5261fc35db89',
          name: 'simple_simple_chip5',
          version: '19496',
          deploy: 'exit_survey',
          template: RestLossAversionCardTemplates.simple_chip,
          requires: {
            title: '<div>Recent account activity</div>',
            activities: [
              {
                icon: 'bbk-esi-users-alt',
                volume: '42',
                unit: '',
                text: 'customers saved',
              },
              {
                icon: 'bbk-esi-radio',
                volume: '60',
                unit: '',
                text: 'watch list',
              },
              {
                icon: 'bbk-esi-chart',
                volume: '$168k',
                unit: '',
                text: 'ARR saved this year',
              },
              {
                icon: 'bbk-esi-chat-active',
                volume: '39',
                unit: '',
                text: 'interactions',
              },
            ],
            forwards: {
              type: 'button',
              text: 'Nevermind',
              action: RestModalAction.nevermind,
              url: 'https://www.brightback.com',
              validation:
                '80a8abcac98decda5d0deae8116b21bb590b2e95ab5b0315bd65c11254dabcaa',
              sync: {
                form_submit: true,
              },
            },
          },
          provides: {},
        },
      ],
      placements: [],
      created_at: '2023-11-29T00:00:00.000Z',
      updated_at: '2023-11-29T00:00:00.000Z',
    },
    group: 'standardOffer',
    category: 'lossAversion',
  },
];

export const defaultOfferPackBrandingCardTemplate = {
  type: 'Card',
  id: 'f54e1f81-1c5a-45a6-96c5-12e8cf0fef87',
  name: 'annual_monthly_and_weekly_plan_default.offer_card_pack.f54e1f81-1c5a-45a6-96c5-12e8cf0fef87',
  version: '2045',
  deploy: 'exit_survey',
  template: 'offer_card_pack',
  requires: {
    content_set: [
      {
        forwards: {
          destination_type: 'url',
          text: 'Upgrade to annual',
          action: 'link',
          url: 'https://vishal-dev-2.com',
          validation:
            'c19f6b5f3c38668a5b72cda1043c5744bd89fc2a114af6a28a8db694907197c2',
          sync: {},
          icon: 'none',
          cta_details_list: [
            {
              cta_id: 'd1500b52-83b9-4afd-8481-aa210a1c0bb3',
              processing_option: 'url',
              text: 'Upgrade to annual',
              category: 'plan_change',
              sub_category: 'upgrade',
              pause_offer_type: 'DELAY',
            },
            {
              cta_id: '8d3e5b46-80bf-4575-8365-ca48e4b36dbf',
              processing_option: 'url',
              text: 'Upgrade to annual',
              category: 'discount',
              discount_option: 'percent',
              amount: 2,
              pause_offer_type: 'DELAY',
            },
          ],
        },
        info: {
          title: 'Annual plan',
          description:
            'You have been with Chargebee Retention for a while now and we value your loyalty to our product!',
          disclaimer1: 'You can cancel anytime.',
          disclaimer2:
            'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
        },
        hero: {
          show: 'image',
          url: 'https://cdn.app.prod.brightback.com/content/immutable/general/7020741a-37fb-4298-b0da-2313ac820fe9.png',
        },
        badge: {
          title: '25% off',
          badge_color: 0,
          badge_text_color: 0,
        },
        price: {
          current_price: '$20',
          new_price: '$15',
          frequency: '/daily',
          hide_price: false,
        },
        activity: {
          item_list: [
            'print edition',
            'doorstep delivery',
            'published Bi-monthly',
          ],
          bullet_image:
            'https://cdn.app.prod.brightback.com/content/immutable/general/7b5e73e7-6173-4495-a49b-1361d707558a.png',
        },
      },
      {
        forwards: {
          destination_type: 'url',
          text: 'Upgrade to monthly',
          action: 'link',
          url: 'https://vishal-dev-2.com',
          validation:
            'c19f6b5f3c38668a5b72cda1043c5744bd89fc2a114af6a28a8db694907197c2',
          sync: {},
          icon: 'none',
          cta_details_list: [
            {
              cta_id: '9068d3c5-ac66-41aa-a625-68ab9f4f6e8e',
              processing_option: 'url',
              text: 'Upgrade to monthly',
              category: 'plan_change',
              sub_category: 'upgrade',
              pause_offer_type: 'DELAY',
            },
            {
              cta_id: '02e83a74-14bb-4870-a47f-cfe9fb477263',
              processing_option: 'url',
              text: 'Upgrade to monthly',
              category: 'discount',
              discount_option: 'fixed',
              currency: 'AOA',
              amount: 3,
              pause_offer_type: 'DELAY',
            },
          ],
        },
        info: {
          title: 'Monthly plan',
          description:
            'You have been with Chargebee Retention for a while now and we value your loyalty to our product!',
          disclaimer1: 'You can cancel anytime.',
          disclaimer2:
            'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
        },
        hero: {
          show: 'image',
          url: 'https://cdn.app.prod.brightback.com/content/immutable/general/5d553c81-62d7-4fb1-9296-4f8664fbc821.png',
        },
        badge: {
          title: '30% off',
          badge_color: 1,
          badge_text_color: 1,
        },
        price: {
          current_price: '$20',
          new_price: '$15',
          frequency: '/daily',
          hide_price: false,
        },
        activity: {
          item_list: [
            'Unlimited access, anytime, anywhere',
            'Digital edition',
            'Read on the go with the app',
            'Access to archives',
          ],
          bullet_image:
            'https://cdn.app.prod.brightback.com/content/immutable/general/7b5e73e7-6173-4495-a49b-1361d707558a.png',
        },
      },
      {
        forwards: {
          destination_type: 'url',
          text: 'Upgrade to weekly',
          action: 'link',
          url: 'https://vishal-dev-2.com',
          validation:
            'c19f6b5f3c38668a5b72cda1043c5744bd89fc2a114af6a28a8db694907197c2',
          sync: {},
          icon: 'none',
          cta_details_list: [
            {
              cta_id: '18e7e710-ccd5-430c-8acc-fdd9ac83f91e',
              processing_option: 'url',
              text: 'Upgrade to weekly',
              category: 'plan_change',
              sub_category: 'downgrade',
              pause_offer_type: 'DELAY',
            },
            {
              cta_id: '85823848-827b-4ca2-bd49-71aa0c70e697',
              processing_option: 'url',
              text: 'Upgrade to weekly',
              category: 'discount',
              discount_option: 'percent',
              amount: 2,
              pause_offer_type: 'DELAY',
            },
          ],
        },
        info: {
          title: 'Weekly plan',
          description:
            'You have been with Chargebee Retention for a while now and we value your loyalty to our product!',
          disclaimer1: 'You can cancel anytime.',
          disclaimer2:
            'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
        },
        hero: {
          show: 'image',
          url: 'https://cdn.app.prod.brightback.com/content/immutable/general/529a9f01-b464-4edf-bbee-564c1c44565f.png',
        },
        badge: {
          title: '20% off',
          badge_color: 2,
          badge_text_color: 2,
        },
        price: {
          current_price: '$20',
          new_price: '$15',
          frequency: '/daily',
          hide_price: false,
        },
        activity: {
          item_list: [
            'Subscribe to other magazines with DashStack',
            'Great value deals',
            'Yearly offers',
            'Doorstep delivery',
          ],
          bullet_image:
            'https://cdn.app.prod.brightback.com/content/immutable/general/7b5e73e7-6173-4495-a49b-1361d707558a.png',
        },
      },
    ],
    info: {},
  },
  provides: {},
  cards: [
    {
      requires: {
        forwards: {
          cta_details: {},
        },
        other_actions: [
          {
            cta_details: {},
          },
        ],
        content_set: [
          {
            forwards: {
              cta_details: {},
            },
          },
          {
            forwards: {
              cta_details: {},
            },
          },
          {
            forwards: {
              cta_details: {},
            },
          },
        ],
      },
    },
  ],
};
