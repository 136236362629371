import type { Optional } from 'utility-types';
import type { RestLossAversionCardOrModalCard } from '@bbkAdminUtils/api-client/rest-cards-types';
import type { RestLossAversionCard } from '@bbkAdminUtils/api-client/rest-la-cards-types';
import type { RestFunnelPlacement } from '@bbkAdminRedux/rtkq/placements.utils';
import type { Prettify } from '@bbkAdminUtils/utility-types';
import type { PLANS } from '@bbkAdminRedux/rtkq/checkout.plans';
import type { RestModalCard } from '@bbkAdminUtils/api-client/rest-modals-cards-types';

export enum PublishState {
  draft = 'draft',
  live = 'live',
}

export enum OfferCategory {
  discount = 'discount',
  pause = 'pause',
  plan_change = 'plan_change',
  extension = 'extension',
  support = 'support',
  customer_feedback = 'customer_feedback',
  gifts_and_credits = 'gifts_and_credits',
  multi_cta = 'multi_cta',
  multi_action = 'multi_action',
  other = 'other',
  offer_pack = 'offer_pack',
}

export enum OfferObjective {
  upsell = 'UPSELL',
  churn_prevention = 'CHURN_PREVENTION',
  churn_deflection = 'CHURN_DEFLECT',
}

export enum OfferPresentation {
  // this will be removed
  image_modal = 'image_modal',
  modal = 'modal',
  pop_up = 'pop_up',
  card = 'card',
  banner = 'banner',
}

export const GenAiOfferCategories = [
  OfferCategory.discount,
  OfferCategory.extension,
  OfferCategory.pause,
  OfferCategory.plan_change,
  OfferCategory.multi_action,
];

export enum OfferGroup {
  offer = 'offer',
  lifecycle_offer = 'lifecycle_offer',
  loss_version = 'loss_version',
}

export enum OfferCtaSubcategory {
  extend_renewal_date = 'extend_renewal_date',
  extend_trial = 'extend_trial',
  upgrade = 'upgrade',
  downgrade = 'downgrade',
  swap_product = 'swap_product',
  gift = 'gift',
  credit = 'credit',
  chat = 'chat',
  content = 'content',
  free_training = 'free_training',
  kb_article = 'kb_article',
  onboarding = 'onboarding',
  schedule_time = 'schedule_time',
  send_email = 'send_email',
  video = 'video',
  customer_service = 'customer_service',
  general_feedback = 'general_feedback',
  product_feedback = 'product_feedback',
  sorry = 'sorry',
  pause_plan = 'pause_plan',
  dollar = 'dollar',
  precent = 'precent',
  trial_conversion_to_new = 'trial_conversion_to_new',
  trial_conversion_to_current = 'trial_conversion_to_current',
}

export const TRIAL_CONVERSION_SUB_CATEGORIES = [
  OfferCtaSubcategory.trial_conversion_to_current,
  OfferCtaSubcategory.trial_conversion_to_new,
];

export const ContentTypeToSubcategoryMap: Partial<
  Record<OfferCtaSubcategory, string>
> = {
  [OfferCtaSubcategory.extend_renewal_date]: 'extend_renewal_dates',
  [OfferCtaSubcategory.swap_product]: 'swap_products',
};

export const OfferCtaSubcategoryDisplayName: Record<
  OfferCtaSubcategory,
  string
> = {
  [OfferCtaSubcategory.extend_renewal_date]: 'Extend Renewal Dates',
  [OfferCtaSubcategory.extend_trial]: 'Extend Trial',
  [OfferCtaSubcategory.upgrade]: 'Upgrade',
  [OfferCtaSubcategory.downgrade]: 'Downgrade',
  [OfferCtaSubcategory.swap_product]: 'Swap Products',
  [OfferCtaSubcategory.gift]: 'Gift',
  [OfferCtaSubcategory.credit]: 'Credits',
  [OfferCtaSubcategory.chat]: 'Chat',
  [OfferCtaSubcategory.content]: 'Content',
  [OfferCtaSubcategory.free_training]: 'Free Training',
  [OfferCtaSubcategory.kb_article]: 'KB article',
  [OfferCtaSubcategory.onboarding]: 'Onboarding',
  [OfferCtaSubcategory.schedule_time]: 'Schedule Time',
  [OfferCtaSubcategory.send_email]: 'Send Email',
  [OfferCtaSubcategory.video]: 'Video',
  [OfferCtaSubcategory.customer_service]: 'Customer Service',
  [OfferCtaSubcategory.general_feedback]: 'General Feedback',
  [OfferCtaSubcategory.product_feedback]: 'Product Feedback',
  [OfferCtaSubcategory.sorry]: 'Sorry',
  [OfferCtaSubcategory.pause_plan]: 'Pause Plan',
  [OfferCtaSubcategory.precent]: 'Discount (%)',
  [OfferCtaSubcategory.dollar]: 'Discount ($)',
  [OfferCtaSubcategory.trial_conversion_to_current]:
    'Trial Conversion to Current Plan',
  [OfferCtaSubcategory.trial_conversion_to_new]: 'Trial Conversion to New Plan',
};

const OfferCtaCategory: Partial<
  Record<OfferCategory, OfferCtaSubcategory[] | undefined>
> = {
  [OfferCategory.discount]: undefined,
  [OfferCategory.pause]: undefined,
  [OfferCategory.extension]: [
    OfferCtaSubcategory.extend_renewal_date,
    OfferCtaSubcategory.extend_trial,
  ],
  [OfferCategory.plan_change]: [
    OfferCtaSubcategory.upgrade,
    OfferCtaSubcategory.downgrade,
    OfferCtaSubcategory.swap_product,
    OfferCtaSubcategory.trial_conversion_to_current,
    OfferCtaSubcategory.trial_conversion_to_new,
  ],
  [OfferCategory.gifts_and_credits]: [
    OfferCtaSubcategory.gift,
    OfferCtaSubcategory.credit,
  ],
  [OfferCategory.support]: [
    OfferCtaSubcategory.chat,
    OfferCtaSubcategory.content,
    OfferCtaSubcategory.free_training,
    OfferCtaSubcategory.kb_article,
    OfferCtaSubcategory.onboarding,
    OfferCtaSubcategory.schedule_time,
    OfferCtaSubcategory.send_email,
    OfferCtaSubcategory.video,
  ],
  [OfferCategory.customer_feedback]: [
    OfferCtaSubcategory.customer_service,
    OfferCtaSubcategory.general_feedback,
    OfferCtaSubcategory.product_feedback,
    OfferCtaSubcategory.sorry,
  ],
  [OfferCategory.other]: undefined,
};

export const getOfferSubcategories = (
  cat: OfferCategory,
  filter?: (subCat: OfferCtaSubcategory) => boolean
) => OfferCtaCategory[cat]?.filter(filter ?? (() => true));

export enum OfferCtaDiscountOption {
  percent = 'percent',
  fixed = 'fixed',
}

export const OfferCtaDiscountOptionDisplayName: Record<
  OfferCtaDiscountOption,
  string
> = {
  [OfferCtaDiscountOption.fixed]: '$ based discount',
  [OfferCtaDiscountOption.percent]: '% based discount',
};

type BillingOfferKeys = {
  coupon_id?: string;
  plan_id?: string;
  interval_count?: number;
  billing_interval?: 'day' | 'week' | 'month' | 'year';
};

enum TagPrefixes {
  plans = 'plans',
}

type Tags = `${TagPrefixes.plans}:${PLANS}`[];

type MaxRedemption = {
  max_redemption_count: number;
};

export type RestOffer = {
  active: boolean;
  id: string;
  display_name: string;
  internal_name: string;
  published_at?: string;
  updated_at?: string;
  created_at?: string;
  category: OfferCategory;
  type: OfferCtaSubcategory;
  primary_card_name: string;
  groups: OfferGroup[];
  cards: RestLossAversionCardOrModalCard[];
  tags?: Tags;
  placements: RestFunnelPlacement[];
  objective: OfferObjective;
  presentation: OfferPresentation;
} & BillingOfferKeys &
  MaxRedemption;

export type NewOffer = RestOffer & {
  isNew: boolean;
  id: string;
};

export function isNewOffer(offer: RestOffer | NewOffer): offer is NewOffer {
  return (offer as NewOffer).isNew;
}

export function isChurnDeflect(offer: RestOffer | NewOffer): boolean {
  return offer.objective === OfferObjective.churn_deflection;
}

export function isNewLossAversion(
  lossAversion: RestLossAversion | NewLossAversionTemplate
): lossAversion is NewLossAversionTemplate {
  return (lossAversion as NewLossAversionTemplate).isNew;
}

export type RestLossAversion = {
  active: boolean;
  id: string;
  display_name: string;
  internal_name: string;
  updated_at: string;
  created_at: string;
  published_at?: string;
  type: string;
  category: string;
  primary_card_name: string;
  cards: (RestLossAversionCard | RestModalCard)[];
  objective: OfferObjective;
  presentation: OfferPresentation;
};

export type NewLossAversionTemplate = RestLossAversion & {
  isNew: true;
};

export type CreateLossAversionDto = Prettify<
  Optional<NewLossAversionTemplate, 'id' | 'isNew'>
>;
