import React, { lazy } from 'react';
import { useSelector } from 'react-redux';
import { Redirect, Route, Switch, useHistory } from 'react-router-dom';
import {
  selectAppId,
  selectCompanyInternalName,
} from '@bbkAdminRedux/app/selectors';
import { useCreatePagesListUrl, useUrl } from '@bbkAdminUtils/nav';
import { RenderInsideMainContent } from '@bbkAdminRouter/render-inside-main';
import { NotFoundPage } from '@bbkAdminComponents/not-found.page';
import { AuthorizedPaths } from '@bbkAdminRouter/router-paths';
import { useQueryFeatureFlags } from '@bbkAdminRedux/rtkq/feature-flags.slice';
import SuspenseLoader from '@bbkAdminComponents/loaders/suspense-loader';
import { useRoles } from '@bbkAdminUtils/roles';
import { FeatureFlagsE, useFeatureFlags } from '@bbkAdminUtils/feature-flags';
import type { ReactFCC } from '@bbkAdminUtils/utility-types';
import { usePendo } from '@bbkAdminUtils/usePendo';

const Install = lazy(() => import('@bbkAdminComponents/settings/install'));
const Dashboard = lazy(() => import('@bbkAdminComponents/dashboard/dashboard'));
const Settings = lazy(() => import('@bbkAdminComponents/settings/settings'));
const Integrations = lazy(
  () => import('@bbkAdminComponents/settings/integrations')
);
const Setup = lazy(() => import('@bbkAdminComponents/settings/setup'));
const Developers = lazy(
  () => import('@bbkAdminComponents/settings/developers')
);
const Permissions = lazy(
  () => import('@bbkAdminComponents/settings/permissions')
);
const Users = lazy(() => import('@bbkAdminComponents/settings/users'));
const Plans = lazy(() => import('@bbkAdminComponents/settings/plans/plans'));

const PlayManager = lazy(
  () => import('@bbkAdminComponents/play/plays-manager')
);

const PlayEditor = lazy(() => import('@bbkAdminComponents/play/play-editor'));

const InviteUsers = lazy(
  () => import('@bbkAdminComponents/settings/invite-users')
);
const AttributionInsights = lazy(
  () => import('@bbkAdminComponents/insights/attribution-insights-report')
);
const TrendsReportGeneral = lazy(
  () => import('@bbkAdminComponents/trends-report/trends-report-general')
);
const CustomersPage = lazy(
  () => import('@bbkAdminComponents/customers/customers-page')
);
const CustomerDetailsPage = lazy(
  () => import('@bbkAdminComponents/customers/customer-details.page')
);
const DataManagement = lazy(
  () => import('@bbkAdminComponents/data-management/data-management')
);
const ConfirmationPages = lazy(
  () =>
    import(
      '@bbkAdminComponents/experiences/funnels-management/cancel-confirmation-funnels/confirmation-pages'
    )
);
const ConfirmationPageDesigner = lazy(
  () =>
    import(
      '@bbkAdminComponents/experiences/funnels-management/cancel-confirmation-funnels/confirmation-page'
    )
);
const PagesListPage = lazy(
  () =>
    import('@bbkAdminComponents/experiences/funnels-management/pages-list-page')
);
const AudiencesManagementPage = lazy(
  () => import('@bbkAdminComponents/experiences/audiences/audiences-page')
);
const LossAversionListPage = lazy(
  () =>
    import(
      '@bbkAdminComponents/experiences/loss-aversion/loss-aversions-list-page'
    )
);
const LossAversionNewPage = lazy(
  () =>
    import(
      '@bbkAdminComponents/experiences/loss-aversion/new-loss-aversion-page'
    )
);
const OffersManagementPage = lazy(
  () => import('@bbkAdminComponents/experiences/offers/offers-management-page')
);
const OffersEditOfferPage = lazy(
  () => import('@bbkAdminComponents/experiences/offers/edit-offer-page')
);
const Designer = lazy(
  () => import('@bbkAdminComponents/designer/designer-page')
);
const ReasonsEditorPage = lazy(
  () => import('@bbkAdminComponents/experiences/funnel/reasons-editor-page')
);
const SubscriptionsPage = lazy(
  () => import('@bbkAdminComponents/subscriptions-page/subscriptions-page')
);
const AlertEditPage = lazy(
  () => import('@bbkAdminComponents/subscriptions-page/alerts/alert-edit-page')
);
const WebhookEditPage = lazy(
  () =>
    import('@bbkAdminComponents/subscriptions-page/webhooks/webhook-edit-page')
);
const ActivityLogPage = lazy(
  () => import('@bbkAdminComponents/activity-log/activity-log-page')
);
const OfferPerformance = lazy(
  () => import('@bbkAdminComponents/offer-performance/offer-performance-v2')
);
const ExperiencePerformanceReportV2 = lazy(
  () =>
    import(
      '@bbkAdminComponents/experience-performance-report/exp-perf-report-v2'
    )
);

const RetainedRevenueCohortReport = lazy(
  () =>
    import(
      '@bbkAdminComponents/retained-revenue-cohort-report/retained-revenue-cohort-report'
    )
);

const LiftDashboard = lazy(
  () => import('@bbkAdminComponents/lift-dashboard/lift-dashboard')
);

const ExperiencePreview = lazy(
  () => import('@bbkAdminComponents/designer/experience-preview')
);
const ExperienceFullPreview = lazy(
  () => import('@bbkAdminComponents/designer/experience-full-preview')
);
const TargetingPage = lazy(
  () => import('@bbkAdminComponents/experiences/targeting/targeting-page')
);
const NewEditRulePage = lazy(
  () =>
    import(
      '@bbkAdminComponents/experiences/audiences/edit-rule/new-edit-rule-page'
    )
);
const BrandingPage = lazy(
  () => import('@bbkAdminComponents/experiences/branding/branding')
);
const BrandListPage = lazy(
  () => import('@bbkAdminComponents/experiences/branding/brand-list')
);

const BrandDetailsPage = lazy(
  () => import('@bbkAdminComponents/experiences/branding/brand-details')
);

const NewBrandingPage = lazy(
  () => import('@bbkAdminComponents/experiences/branding/branding-drawer')
);

const GetStartedPage = lazy(
  () => import('@bbkAdminComponents/get-started/get-started-page')
);
const GetStartedPageCreateExperience = lazy(
  () => import('@bbkAdminComponents/get-started/create-experience-page')
);
const CustomDomain = lazy(
  () => import('@bbkAdminComponents/settings/custom-domain')
);

const baseAuthPath = '/company/:companyKey/app/:appKey';

export const AuthorizedRouter: ReactFCC = () => {
  const history = useHistory();
  const createUrl = useUrl();

  const companyKey = useSelector(selectCompanyInternalName);
  const appKey = useSelector(selectAppId);
  const { hasBillingRole } = useRoles();

  usePendo();

  const enableAdminUsersPageOverride = useFeatureFlags(
    FeatureFlagsE.SHOW_ADMIN_USERS_PAGE_OVERRIDE
  );

  const isBillingAdmin = hasBillingRole || enableAdminUsersPageOverride;

  const isPlayEnabled = useFeatureFlags(FeatureFlagsE.ENABLE_LIFECYCLE_OFFERS);

  const isPlayReportingEnabled = useFeatureFlags(
    FeatureFlagsE.ENABLE_LIFECYCLE_REPORTS
  );

  const audiencesManagementUrl = createUrl(AuthorizedPaths.audiencesList);

  const { isLoading: isFFLoading } = useQueryFeatureFlags();

  const pagesListUrl = useCreatePagesListUrl();

  if (isFFLoading) return <SuspenseLoader message=" " />;

  return (
    <Switch>
      <Redirect path="/company" exact to={`/company/${companyKey}/`} />
      <Redirect path="/company/" exact to={`/company/${companyKey}/`} />
      <Redirect path="/company/sign-in" exact to={`/company/${companyKey}/`} />
      <Redirect
        path="/company/:companyKey/"
        exact
        to="/company/:companyKey/app/"
      />
      <Redirect
        path="/company/:companyKey/app/"
        exact
        to={`/company/:companyKey/app/${appKey}/`}
      />
      <Redirect
        path={`${baseAuthPath}/`}
        exact
        to={`${baseAuthPath}${AuthorizedPaths.dashboard}`}
      />
      <Route
        path={`${baseAuthPath}${AuthorizedPaths.dashboard}`}
        exact
        render={() => RenderInsideMainContent(<Dashboard />)}
      />
      <Route
        path={`${baseAuthPath}${AuthorizedPaths.getStarted}`}
        exact
        render={() => RenderInsideMainContent(<GetStartedPage />)}
      />
      <Route
        path={[
          `${baseAuthPath}${AuthorizedPaths.getStartedCreateExperienceStep1Branding}`,
          `${baseAuthPath}${AuthorizedPaths.getStartedCreateExperienceStep2Offers}`,
          `${baseAuthPath}${AuthorizedPaths.getStartedCreateExperienceStep3Targeting}`,
        ]}
        exact
        render={() =>
          RenderInsideMainContent(<GetStartedPageCreateExperience />)
        }
      />
      <Route
        path={[
          `${baseAuthPath}${AuthorizedPaths.experiencesFunnelPreview}`,
          `${baseAuthPath}${AuthorizedPaths.experiencesHostedPreview}`,
        ]}
        exact
        render={() => RenderInsideMainContent(<ExperiencePreview />)}
      />
      <Route
        path={[
          `${baseAuthPath}${AuthorizedPaths.experiencesFunnelPreviewFull}`,
          `${baseAuthPath}${AuthorizedPaths.experiencesHostedPreviewFull}`,
        ]}
        exact
        render={() => RenderInsideMainContent(<ExperienceFullPreview />)}
      />

      {/* Play */}
      {isPlayEnabled && (
        <Route
          path={`${baseAuthPath}${AuthorizedPaths.plays}`}
          exact
          render={() => RenderInsideMainContent(<PlayManager />)}
        />
      )}
      {isPlayEnabled && (
        <Route
          path={[
            `${baseAuthPath}${AuthorizedPaths.playEdit}`,
            `${baseAuthPath}${AuthorizedPaths.playNew}`,
          ]}
          exact
          render={() => RenderInsideMainContent(<PlayEditor editMode={true} />)}
        />
      )}
      {isPlayEnabled && isPlayReportingEnabled && (
        <Route
          path={`${baseAuthPath}${AuthorizedPaths.playReports}`}
          exact
          render={() =>
            RenderInsideMainContent(<PlayEditor editMode={false} />)
          }
        />
      )}

      {/* Experiences */}
      <Redirect
        path={`${baseAuthPath}/experiences`}
        exact
        to={`${baseAuthPath}${AuthorizedPaths.experiencesBrandingPage}`}
      />
      <Redirect
        path={`${baseAuthPath}${AuthorizedPaths.experiencesManager}`}
        exact
        to={pagesListUrl}
      />
      <Route
        path={`${baseAuthPath}${AuthorizedPaths.experiencesBrandingPage}`}
        exact
        render={() => RenderInsideMainContent(<BrandingPage />)}
      />

      <Route
        path={`${baseAuthPath}${AuthorizedPaths.additionalBrandsPage}`}
        exact
        render={() => RenderInsideMainContent(<BrandListPage />)}
      />
      <Route
        path={[
          `${baseAuthPath}${AuthorizedPaths.createAdditionalBrand}`,
          `${baseAuthPath}${AuthorizedPaths.viewAdditionalBrand}`,
        ]}
        exact
        render={() => RenderInsideMainContent(<BrandDetailsPage />)}
      />
      <Route
        path={`${baseAuthPath}${AuthorizedPaths.editAdditionalBranding}`}
        exact
        render={() => RenderInsideMainContent(<NewBrandingPage />)}
      />
      <Route
        path={`${baseAuthPath}${AuthorizedPaths.experiencesPages}`}
        exact
        render={() => RenderInsideMainContent(<PagesListPage />)}
      />
      <Route
        path={`${baseAuthPath}${AuthorizedPaths.confirmationPages}`}
        exact
        render={() => RenderInsideMainContent(<ConfirmationPages />)}
      />
      <Route
        path={`${baseAuthPath}${AuthorizedPaths.confirmationPagesCreate}`}
        exact
        render={() => RenderInsideMainContent(<ConfirmationPageDesigner />)}
      />
      <Route
        path={`${baseAuthPath}${AuthorizedPaths.confirmationPagesEdit}`}
        exact
        render={() => RenderInsideMainContent(<ConfirmationPageDesigner />)}
      />

      <Route // Targeting
        path={`${baseAuthPath}${AuthorizedPaths.experiencesTargeting}`}
        exact
        render={() => RenderInsideMainContent(<TargetingPage />)}
      />
      <Route
        path={`${baseAuthPath}${AuthorizedPaths.audiencesList}`}
        exact
        render={() => RenderInsideMainContent(<AudiencesManagementPage />)}
      />

      <Route
        path={[
          `${baseAuthPath}${AuthorizedPaths.audiencesNew}`,
          `${baseAuthPath}${AuthorizedPaths.audiencesEdit}`,
        ]}
        exact
        render={() =>
          RenderInsideMainContent(
            <NewEditRulePage
              open
              onClose={() => history.push(audiencesManagementUrl)}
              onSave={() => history.push(audiencesManagementUrl)}
            />
          )
        }
      />

      <Route
        path={`${baseAuthPath}${AuthorizedPaths.experiencesBrandManager}`}
        exact
        render={() => RenderInsideMainContent(<PagesListPage />)}
      />
      <Route
        path={[
          `${baseAuthPath}${AuthorizedPaths.experiencesFunnelsPage}`,
          `${baseAuthPath}${AuthorizedPaths.experiencesHostedPage}`,
        ]}
        exact
        render={() => RenderInsideMainContent(<Designer />)}
      />
      <Route
        path={`${baseAuthPath}${AuthorizedPaths.experiencesFunnelsPageConfirmation}`}
        exact
        render={() => RenderInsideMainContent(<Designer />)}
      />
      <Redirect
        path={`${baseAuthPath}${AuthorizedPaths.experiencesFunnelsPageReasons}`}
        exact
        to={`${baseAuthPath}${AuthorizedPaths.experiencesPageReasons}`}
      />
      <Route
        path={`${baseAuthPath}${AuthorizedPaths.experiencesPageReasons}`}
        render={() => RenderInsideMainContent(<ReasonsEditorPage />)}
      />

      <Route
        path={`${baseAuthPath}${AuthorizedPaths.lossAversionNew}`}
        exact
        render={() => RenderInsideMainContent(<LossAversionNewPage />)}
      />
      <Route
        path={[
          `${baseAuthPath}${AuthorizedPaths.lossAversionList}`,
          `${baseAuthPath}${AuthorizedPaths.lossAversionEdit}`,
          `${baseAuthPath}${AuthorizedPaths.lossAversionPlacement}`,
        ]}
        exact
        render={() => RenderInsideMainContent(<LossAversionListPage />)}
      />

      {/* Offers */}
      <Route
        path={[
          `${baseAuthPath}${AuthorizedPaths.offers}`,
          `${baseAuthPath}${AuthorizedPaths.offersNew}`,
        ]}
        exact
        render={() => RenderInsideMainContent(<OffersManagementPage />)}
      />

      <Route
        path={[
          `${baseAuthPath}${AuthorizedPaths.offersEdit}`,
          `${baseAuthPath}${AuthorizedPaths.offersPlacement}`,
          `${baseAuthPath}${AuthorizedPaths.offersBilling}`,
          `${baseAuthPath}${AuthorizedPaths.offersEditDesign}`,
          `${baseAuthPath}${AuthorizedPaths.offersEditActivities}`,
          `${baseAuthPath}${AuthorizedPaths.offersEditItems}`,
          `${baseAuthPath}${AuthorizedPaths.offersEditSettings}`,
          `${baseAuthPath}${AuthorizedPaths.offersEditCtaSettings}`,
          `${baseAuthPath}${AuthorizedPaths.offersEditCtaState}`,
          `${baseAuthPath}${AuthorizedPaths.offersEditAITopLevel}`,
          `${baseAuthPath}${AuthorizedPaths.offersEditBranding}`,
          `${baseAuthPath}${AuthorizedPaths.offerPosition}`,
        ]}
        exact
        render={() => RenderInsideMainContent(<OffersEditOfferPage />)}
      />

      {/* Trends */}
      <Route
        path={`${baseAuthPath}${AuthorizedPaths.trendsGraphs}`}
        exact
        render={() => RenderInsideMainContent(<TrendsReportGeneral />)}
      />
      <Redirect
        path={`${baseAuthPath}/trends/:trendsPage`}
        exact
        to={`${baseAuthPath}${AuthorizedPaths.trendsCancels}`}
      />

      {/* Insights */}
      <Route
        path={`${baseAuthPath}${AuthorizedPaths.insightsCancelAI}`}
        exact
        render={() =>
          RenderInsideMainContent(<AttributionInsights isAI={true} />)
        }
      />
      <Route
        path={`${baseAuthPath}${AuthorizedPaths.insightsCancel}`}
        exact
        render={() => RenderInsideMainContent(<AttributionInsights />)}
      />
      <Route
        path={`${baseAuthPath}${AuthorizedPaths.insightsExperiencePerformanceReport}`}
        exact
        render={() =>
          RenderInsideMainContent(<ExperiencePerformanceReportV2 />)
        }
      />
      <Route
        path={`${baseAuthPath}${AuthorizedPaths.insightsOfferPerformance}`}
        exact
        render={() => RenderInsideMainContent(<OfferPerformance />)}
      />
      <Route
        path={`${baseAuthPath}${AuthorizedPaths.insightsRetainedRevenueCohort}`}
        exact
        render={() => RenderInsideMainContent(<RetainedRevenueCohortReport />)}
      />
      <Route
        path={`${baseAuthPath}${AuthorizedPaths.insightsLiftDashboard}`}
        exact
        render={() => RenderInsideMainContent(<LiftDashboard />)}
      />

      {/* Customers */}
      <Route
        path={`${baseAuthPath}${AuthorizedPaths.customers}`}
        exact
        render={() => RenderInsideMainContent(<CustomersPage />)}
      />
      {/* Customer Details */}
      <Route
        path={`${baseAuthPath}${AuthorizedPaths.customersDetail}`}
        render={() => RenderInsideMainContent(<CustomerDetailsPage />)}
      />

      {/* Settings */}
      <Route
        path={`${baseAuthPath}${AuthorizedPaths.settings}`}
        exact
        render={() => RenderInsideMainContent(<Settings />)}
      />
      <Route
        path={`${baseAuthPath}${AuthorizedPaths.settingsSubscriptions}`}
        exact
        render={() => RenderInsideMainContent(<SubscriptionsPage />)}
      />
      <Route
        path={`${baseAuthPath}${AuthorizedPaths.settingsAlerts}`}
        exact
        render={() => RenderInsideMainContent(<AlertEditPage />)}
      />
      <Route
        path={`${baseAuthPath}${AuthorizedPaths.settingsWebhooks}`}
        exact
        render={() => RenderInsideMainContent(<WebhookEditPage />)}
      />
      <Route
        path={`${baseAuthPath}${AuthorizedPaths.settingsIntegrations}`}
        exact
        render={() => RenderInsideMainContent(<Integrations />)}
      />
      <Route
        path={`${baseAuthPath}${AuthorizedPaths.settingsSetup}`}
        exact
        render={() => RenderInsideMainContent(<Setup />)}
      />
      <Route
        path={`${baseAuthPath}${AuthorizedPaths.settingsDevelopers}`}
        exact
        render={() => RenderInsideMainContent(<Developers />)}
      />
      <Route
        path={`${baseAuthPath}${AuthorizedPaths.settingsPermissions}`}
        exact
        render={() => RenderInsideMainContent(<Permissions />)}
      />
      <Route
        path={`${baseAuthPath}${AuthorizedPaths.settingsRestrictedDomainsManage}`}
        exact
        render={() => RenderInsideMainContent(<Users />)}
      />
      <Route
        path={`${baseAuthPath}${AuthorizedPaths.settingsCustomDomain}`}
        exact
        render={() => RenderInsideMainContent(<CustomDomain />)}
      />
      <Route
        path={`${baseAuthPath}${AuthorizedPaths.settingsFieldMappings}`}
        exact
        render={() => RenderInsideMainContent(<DataManagement />)}
      />
      <Route
        path={`${baseAuthPath}${AuthorizedPaths.settingsInstall}`}
        exact
        render={() => RenderInsideMainContent(<Install />)}
      />
      {isBillingAdmin && (
        <Route
          path={`${baseAuthPath}${AuthorizedPaths.settingsUsers}`}
          exact
          render={() => RenderInsideMainContent(<Users />)}
        />
      )}
      <Route
        path={[`${baseAuthPath}${AuthorizedPaths.settingsPlans}`]}
        exact
        render={() => RenderInsideMainContent(<Plans />)}
      />
      <Route
        path={`${baseAuthPath}${AuthorizedPaths.settingsUsersInvite}`}
        exact
        render={() => RenderInsideMainContent(<InviteUsers />)}
      />
      <Route
        path={`${baseAuthPath}${AuthorizedPaths.settingsActivityLog}`}
        exact
        render={() => RenderInsideMainContent(<ActivityLogPage />)}
      />
      <Redirect
        path={`${baseAuthPath}${AuthorizedPaths.settings}`}
        to={`${baseAuthPath}${AuthorizedPaths.settings}`}
      />
      <Route component={NotFoundPage} />
    </Switch>
  );
};
