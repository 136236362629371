import type { EmotionJSX } from '@emotion/react/types/jsx-namespace';
import { NavIcons } from '@bbkAdminComponents/app-layout/nav';
import type { PublishState } from '@bbkAdminUtils/api-client/rest-offers-api';

export enum PlacementLossAversionPosition {
  loss_aversion_left = 'loss_aversion_left',
  loss_aversion_right = 'loss_aversion_right',
  loss_aversion_top_row = 'loss_aversion_top_row',
  loss_aversion_bottom_row = 'loss_aversion_bottom_row',
  loss_aversion_bottom_left = 'loss_aversion_bottom_left',
  loss_aversion_bottom_right = 'loss_aversion_bottom_right',
}

export const HorizontalLaPositions = [
  PlacementLossAversionPosition.loss_aversion_top_row,
  PlacementLossAversionPosition.loss_aversion_bottom_row,
];

export const EligibleLALayouts: {
  layout: PlacementLossAversionPosition[];
  label: 'A' | 'B' | 'C' | 'D' | 'E' | 'F' | 'G' | 'H' | 'I';
  icon: EmotionJSX.Element;
  longable?: boolean;
}[] = [
  {
    layout: [],
    icon: NavIcons.none,
    label: 'A',
  },
  {
    layout: [PlacementLossAversionPosition.loss_aversion_top_row],
    icon: NavIcons.top,
    label: 'B',
  },
  {
    layout: [
      PlacementLossAversionPosition.loss_aversion_left,
      PlacementLossAversionPosition.loss_aversion_right,
    ],
    icon: NavIcons.leftRight,
    label: 'C',
  },
  {
    layout: [
      PlacementLossAversionPosition.loss_aversion_top_row,
      PlacementLossAversionPosition.loss_aversion_bottom_row,
    ],
    icon: NavIcons.topBottom,
    label: 'D',
  },
  {
    layout: [
      PlacementLossAversionPosition.loss_aversion_top_row,
      PlacementLossAversionPosition.loss_aversion_bottom_left,
      PlacementLossAversionPosition.loss_aversion_bottom_right,
    ],
    icon: NavIcons.topLeftRight,
    label: 'E',
  },
  {
    layout: [
      PlacementLossAversionPosition.loss_aversion_left,
      PlacementLossAversionPosition.loss_aversion_right,
      PlacementLossAversionPosition.loss_aversion_bottom_row,
    ],
    icon: NavIcons.leftRightBottom,
    label: 'F',
  },
  {
    layout: [
      PlacementLossAversionPosition.loss_aversion_left,
      PlacementLossAversionPosition.loss_aversion_right,
      PlacementLossAversionPosition.loss_aversion_bottom_left,
      PlacementLossAversionPosition.loss_aversion_bottom_right,
    ],
    icon: NavIcons.leftRightLeftRight,
    label: 'G',
  },
  {
    layout: [
      PlacementLossAversionPosition.loss_aversion_left,
      PlacementLossAversionPosition.loss_aversion_right,
      PlacementLossAversionPosition.loss_aversion_bottom_right,
    ],
    icon: NavIcons.leftRightRight,
    longable: true,
    label: 'H',
  },
  {
    layout: [
      PlacementLossAversionPosition.loss_aversion_left,
      PlacementLossAversionPosition.loss_aversion_bottom_left,
      PlacementLossAversionPosition.loss_aversion_right,
    ],
    icon: NavIcons.leftLeftRight,
    longable: true,
    label: 'I',
  },
];

export enum PlacementOfferCardpack {
  offer_pack_center = 'offer_pack_center',
}

export const getTransition = (
  fromLayout: (typeof EligibleLALayouts)[number]['label'],
  fromPosition: PlacementLossAversionPosition,
  toLayout: (typeof EligibleLALayouts)[number]['label']
) => {
  if (fromLayout === toLayout) return fromPosition;
  let toPosition = undefined;
  switch (fromLayout) {
    case 'A':
      return undefined; //nothing to transition
    case 'B':
      switch (toLayout) {
        case 'C':
        case 'G':
        case 'H':
          toPosition = PlacementLossAversionPosition.loss_aversion_left;
          break;
        case 'F':
          toPosition = PlacementLossAversionPosition.loss_aversion_bottom_row;
          break;
        case 'I':
          toPosition = PlacementLossAversionPosition.loss_aversion_right;
          break;
      }
      break;
    case 'C':
      switch (toLayout) {
        case 'B':
          switch (fromPosition) {
            case PlacementLossAversionPosition.loss_aversion_left:
              toPosition = PlacementLossAversionPosition.loss_aversion_top_row;
              break;
          }
          break;
        case 'D':
          switch (fromPosition) {
            case PlacementLossAversionPosition.loss_aversion_left:
              toPosition = PlacementLossAversionPosition.loss_aversion_top_row;
              break;
            case PlacementLossAversionPosition.loss_aversion_right:
              toPosition =
                PlacementLossAversionPosition.loss_aversion_bottom_row;
              break;
          }
          break;
        case 'E':
          switch (fromPosition) {
            case PlacementLossAversionPosition.loss_aversion_left:
              toPosition =
                PlacementLossAversionPosition.loss_aversion_bottom_left;
              break;
            case PlacementLossAversionPosition.loss_aversion_right:
              toPosition =
                PlacementLossAversionPosition.loss_aversion_bottom_right;
              break;
          }
          break;
      }
      break;
    case 'D':
      switch (toLayout) {
        case 'C':
        case 'G':
        case 'H':
        case 'I':
          switch (fromPosition) {
            case PlacementLossAversionPosition.loss_aversion_top_row:
              toPosition = PlacementLossAversionPosition.loss_aversion_left;
              break;
            case PlacementLossAversionPosition.loss_aversion_bottom_row:
              toPosition = PlacementLossAversionPosition.loss_aversion_right;
              break;
          }
          break;
        case 'E':
          switch (fromPosition) {
            case PlacementLossAversionPosition.loss_aversion_bottom_row:
              toPosition =
                PlacementLossAversionPosition.loss_aversion_bottom_left;
              break;
          }
          break;
        case 'F':
          switch (fromPosition) {
            case PlacementLossAversionPosition.loss_aversion_top_row:
              toPosition = PlacementLossAversionPosition.loss_aversion_left;
              break;
          }
          break;
      }
      break;
    case 'E':
      switch (toLayout) {
        case 'C':
          switch (fromPosition) {
            case PlacementLossAversionPosition.loss_aversion_bottom_left:
              toPosition = PlacementLossAversionPosition.loss_aversion_left;
              break;
            case PlacementLossAversionPosition.loss_aversion_bottom_right:
              toPosition = PlacementLossAversionPosition.loss_aversion_right;
              break;
          }
          break;
        case 'D':
          switch (fromPosition) {
            case PlacementLossAversionPosition.loss_aversion_bottom_left:
              toPosition =
                PlacementLossAversionPosition.loss_aversion_bottom_row;
              break;
          }
          break;
        case 'F':
          switch (fromPosition) {
            case PlacementLossAversionPosition.loss_aversion_bottom_left:
              toPosition = PlacementLossAversionPosition.loss_aversion_left;
              break;
            case PlacementLossAversionPosition.loss_aversion_bottom_right:
              toPosition = PlacementLossAversionPosition.loss_aversion_right;
              break;
            case PlacementLossAversionPosition.loss_aversion_top_row:
              toPosition =
                PlacementLossAversionPosition.loss_aversion_bottom_row;
              break;
          }
          break;
        case 'G':
          switch (fromPosition) {
            case PlacementLossAversionPosition.loss_aversion_top_row:
              toPosition = PlacementLossAversionPosition.loss_aversion_left;
              break;
          }
          break;
        case 'H':
          switch (fromPosition) {
            case PlacementLossAversionPosition.loss_aversion_bottom_left:
              toPosition =
                PlacementLossAversionPosition.loss_aversion_bottom_right;
              break;
            case PlacementLossAversionPosition.loss_aversion_bottom_right:
              toPosition = PlacementLossAversionPosition.loss_aversion_right;
              break;
            case PlacementLossAversionPosition.loss_aversion_top_row:
              toPosition = PlacementLossAversionPosition.loss_aversion_left;
              break;
          }
          break;
        case 'I':
          switch (fromPosition) {
            case PlacementLossAversionPosition.loss_aversion_bottom_left:
              toPosition = PlacementLossAversionPosition.loss_aversion_left;
              break;
            case PlacementLossAversionPosition.loss_aversion_bottom_right:
              toPosition =
                PlacementLossAversionPosition.loss_aversion_bottom_left;
              break;
            case PlacementLossAversionPosition.loss_aversion_top_row:
              toPosition = PlacementLossAversionPosition.loss_aversion_right;
              break;
          }
          break;
      }
      break;
    case 'F':
      switch (toLayout) {
        case 'B':
          switch (fromPosition) {
            case PlacementLossAversionPosition.loss_aversion_bottom_row:
              toPosition = PlacementLossAversionPosition.loss_aversion_top_row;
              break;
          }
          break;
        case 'D':
          switch (fromPosition) {
            case PlacementLossAversionPosition.loss_aversion_left:
              toPosition = PlacementLossAversionPosition.loss_aversion_top_row;
              break;
          }
          break;
        case 'E':
          switch (fromPosition) {
            case PlacementLossAversionPosition.loss_aversion_left:
              toPosition =
                PlacementLossAversionPosition.loss_aversion_bottom_left;
              break;
            case PlacementLossAversionPosition.loss_aversion_right:
              toPosition =
                PlacementLossAversionPosition.loss_aversion_bottom_right;
              break;
            case PlacementLossAversionPosition.loss_aversion_bottom_row:
              toPosition = PlacementLossAversionPosition.loss_aversion_top_row;
              break;
          }
          break;
        case 'G':
          switch (fromPosition) {
            case PlacementLossAversionPosition.loss_aversion_bottom_row:
              toPosition =
                PlacementLossAversionPosition.loss_aversion_bottom_left;
              break;
          }
          break;
        case 'H':
          switch (fromPosition) {
            case PlacementLossAversionPosition.loss_aversion_bottom_row:
              toPosition = PlacementLossAversionPosition.loss_aversion_left;
              break;
            case PlacementLossAversionPosition.loss_aversion_left:
              toPosition = PlacementLossAversionPosition.loss_aversion_right;
              break;
            case PlacementLossAversionPosition.loss_aversion_right:
              toPosition =
                PlacementLossAversionPosition.loss_aversion_bottom_right;
              break;
          }
          break;
        case 'I':
          switch (fromPosition) {
            case PlacementLossAversionPosition.loss_aversion_bottom_row:
              toPosition = PlacementLossAversionPosition.loss_aversion_right;
              break;
            case PlacementLossAversionPosition.loss_aversion_left:
              toPosition =
                PlacementLossAversionPosition.loss_aversion_bottom_left;
              break;
            case PlacementLossAversionPosition.loss_aversion_right:
              toPosition = PlacementLossAversionPosition.loss_aversion_left;
              break;
          }
          break;
      }
      break;
    case 'G':
      switch (toLayout) {
        case 'B':
        case 'E':
          switch (fromPosition) {
            case PlacementLossAversionPosition.loss_aversion_left:
              toPosition = PlacementLossAversionPosition.loss_aversion_top_row;
              break;
          }
          break;
        case 'D':
          switch (fromPosition) {
            case PlacementLossAversionPosition.loss_aversion_left:
              toPosition = PlacementLossAversionPosition.loss_aversion_top_row;
              break;
            case PlacementLossAversionPosition.loss_aversion_bottom_left:
              toPosition =
                PlacementLossAversionPosition.loss_aversion_bottom_row;
              break;
          }
          break;
        case 'F':
          switch (fromPosition) {
            case PlacementLossAversionPosition.loss_aversion_bottom_left:
              toPosition =
                PlacementLossAversionPosition.loss_aversion_bottom_row;
              break;
          }
          break;
      }
      break;
    case 'H':
      switch (toLayout) {
        case 'B':
          switch (fromPosition) {
            case PlacementLossAversionPosition.loss_aversion_left:
              toPosition = PlacementLossAversionPosition.loss_aversion_top_row;
              break;
          }
          break;
        case 'D':
          switch (fromPosition) {
            case PlacementLossAversionPosition.loss_aversion_right:
              toPosition = PlacementLossAversionPosition.loss_aversion_top_row;
              break;
            case PlacementLossAversionPosition.loss_aversion_bottom_right:
              toPosition =
                PlacementLossAversionPosition.loss_aversion_bottom_row;
              break;
          }
          break;
        case 'E':
          switch (fromPosition) {
            case PlacementLossAversionPosition.loss_aversion_left:
              toPosition = PlacementLossAversionPosition.loss_aversion_top_row;
              break;
            case PlacementLossAversionPosition.loss_aversion_bottom_right:
              toPosition =
                PlacementLossAversionPosition.loss_aversion_bottom_left;
              break;
            case PlacementLossAversionPosition.loss_aversion_right:
              toPosition =
                PlacementLossAversionPosition.loss_aversion_bottom_right;
              break;
          }
          break;
        case 'F':
          switch (fromPosition) {
            case PlacementLossAversionPosition.loss_aversion_left:
              toPosition =
                PlacementLossAversionPosition.loss_aversion_bottom_row;
              break;
            case PlacementLossAversionPosition.loss_aversion_bottom_right:
              toPosition = PlacementLossAversionPosition.loss_aversion_right;
              break;
            case PlacementLossAversionPosition.loss_aversion_right:
              toPosition = PlacementLossAversionPosition.loss_aversion_left;
              break;
          }
          break;
        case 'I':
          switch (fromPosition) {
            case PlacementLossAversionPosition.loss_aversion_left:
              toPosition = PlacementLossAversionPosition.loss_aversion_right;
              break;
            case PlacementLossAversionPosition.loss_aversion_bottom_right:
              toPosition =
                PlacementLossAversionPosition.loss_aversion_bottom_left;
              break;
            case PlacementLossAversionPosition.loss_aversion_right:
              toPosition = PlacementLossAversionPosition.loss_aversion_left;
              break;
          }
          break;
      }
      break;
    case 'I':
      switch (toLayout) {
        case 'B':
          switch (fromPosition) {
            case PlacementLossAversionPosition.loss_aversion_left:
              toPosition = PlacementLossAversionPosition.loss_aversion_top_row;
              break;
          }
          break;
        case 'D':
          switch (fromPosition) {
            case PlacementLossAversionPosition.loss_aversion_left:
              toPosition = PlacementLossAversionPosition.loss_aversion_top_row;
              break;
            case PlacementLossAversionPosition.loss_aversion_bottom_left:
              toPosition =
                PlacementLossAversionPosition.loss_aversion_bottom_row;
              break;
          }
          break;
        case 'E':
          switch (fromPosition) {
            case PlacementLossAversionPosition.loss_aversion_left:
              toPosition =
                PlacementLossAversionPosition.loss_aversion_bottom_left;
              break;
            case PlacementLossAversionPosition.loss_aversion_bottom_left:
              toPosition =
                PlacementLossAversionPosition.loss_aversion_bottom_right;
              break;
            case PlacementLossAversionPosition.loss_aversion_right:
              toPosition = PlacementLossAversionPosition.loss_aversion_top_row;
              break;
          }
          break;
        case 'F':
          switch (fromPosition) {
            case PlacementLossAversionPosition.loss_aversion_left:
              toPosition = PlacementLossAversionPosition.loss_aversion_right;
              break;
            case PlacementLossAversionPosition.loss_aversion_bottom_left:
              toPosition = PlacementLossAversionPosition.loss_aversion_left;
              break;
            case PlacementLossAversionPosition.loss_aversion_right:
              toPosition =
                PlacementLossAversionPosition.loss_aversion_bottom_row;
              break;
          }
          break;
        case 'H':
          switch (fromPosition) {
            case PlacementLossAversionPosition.loss_aversion_left:
              toPosition = PlacementLossAversionPosition.loss_aversion_right;
              break;
            case PlacementLossAversionPosition.loss_aversion_bottom_left:
              toPosition =
                PlacementLossAversionPosition.loss_aversion_bottom_right;
              break;
            case PlacementLossAversionPosition.loss_aversion_right:
              toPosition = PlacementLossAversionPosition.loss_aversion_left;
              break;
          }
          break;
      }
      break;
  }
  return toPosition;
};

export enum PlacementModalOfferPosition {
  final_offer = 'final_offer',
  first_load_offer = 'first_load_offer',
}

export type RestPosition =
  | PlacementLossAversionPosition
  | PlacementModalOfferPosition;

type RestAbstractFunnelPlacement = {
  funnel_id: number;
  published_at?: string; // as Date
  vanity_reason_id?: number; // Survey Placement
  cancel_confirmation_funnel_key?: string; // CCP
  position?:
    | PlacementLossAversionPosition // Loss Aversion Placement
    | PlacementModalOfferPosition // Modal Offer Card Placement
    | PlacementOfferCardpack; // Hosted Page Offer Card pack placement
};
export type RestFunnelPlacementLossAversion = RestAbstractFunnelPlacement & {
  position: PlacementLossAversionPosition;
  content_id?: string;
};
export type RestHostedPlacementOfferCardpack = RestAbstractFunnelPlacement & {
  position: PlacementOfferCardpack;
  content_id?: string;
};
export type RestFunnelPlacementSurvey = RestAbstractFunnelPlacement & {
  vanity_reason_id: number;
  content_id?: string;
};
export type RestFunnelPlacementModalOfferPosition =
  | RestFunnelPlacementFirstLoadOfferPlacement
  | RestFunnelPlacementFinalOfferPlacement;
export type RestFunnelPlacementFirstLoadOfferPlacement =
  RestAbstractFunnelPlacement & {
    position: PlacementModalOfferPosition.first_load_offer;
    content_id: string;
  };
export type RestFunnelPlacementFinalOfferPlacement =
  RestAbstractFunnelPlacement & {
    position: PlacementModalOfferPosition.final_offer;
    content_id: string;
  };
export type RestFunnelPlacementCancelConfirmation = RestAbstractFunnelPlacement;
export type RestFunnelPlacement =
  | RestFunnelPlacementLossAversion
  | RestFunnelPlacementSurvey
  | RestFunnelPlacementModalOfferPosition
  | RestFunnelPlacementCancelConfirmation
  | RestHostedPlacementOfferCardpack;

export type FunnelPlacements<
  P extends RestAbstractFunnelPlacement = RestFunnelPlacement,
> = {
  id: number;
  placements: P[];
  draft: boolean;
};

export enum PlacementContextType {
  // when we load all reasons page
  reasons = 'reasons',

  // when we load offer or la page
  content = 'content',
}

export type PlacementsResponse = {
  [funnelId: string]: {
    content_placements: RestFunnelPlacement[];
    draft: boolean;
  };
};

export type PlacementsChangeRequest = {
  publish_state: PublishState;
  comment: string;
  placements: RestFunnelPlacement[];
};

export const formatForReducer = (
  response: PlacementsResponse
): FunnelPlacements[] =>
  Object.entries(response).map(([funnelId, { content_placements, draft }]) => ({
    id: parseInt(funnelId, 10),
    placements: content_placements,
    draft,
  }));

export const getPositionDisplayName = (position: RestPosition): string => {
  switch (position) {
    case PlacementLossAversionPosition.loss_aversion_left:
      return 'Loss aversion (top left)';
    case PlacementLossAversionPosition.loss_aversion_right:
      return 'Loss aversion (top right)';
    case PlacementModalOfferPosition.final_offer:
      return 'Final offer';
    case PlacementModalOfferPosition.first_load_offer:
      return 'First offer';
    default:
      return '';
  }
};

export const isPlacementForLossAversion = (
  placement: RestFunnelPlacement
): placement is RestFunnelPlacementLossAversion =>
  !!placement.position &&
  Object.values(PlacementLossAversionPosition).includes(placement.position);

export const isPlacementForSurvey = (
  placement: RestFunnelPlacement
): placement is RestFunnelPlacementSurvey =>
  placement.vanity_reason_id !== undefined;

export const isPlacementForOfferCardpack = (
  placement: RestFunnelPlacement
): placement is RestHostedPlacementOfferCardpack =>
  !!placement.position &&
  Object.values(PlacementOfferCardpack).includes(placement.position);
