import { useMemo } from 'react';
import { useQueryFeatureFlags } from '@bbkAdminRedux/rtkq/feature-flags.slice';

export enum FeatureFlagsE {
  SUPER_ADMIN = 'SUPER_ADMIN',

  CAN_VIEW_COMPANY_DATA = 'CAN_VIEW_COMPANY_DATA',
  CAN_CREATE_NEW_COMPANY = 'CAN_CREATE_NEW_COMPANY',
  CAN_UPDATE_COMPANY_DATA = 'CAN_UPDATE_COMPANY_DATA',
  CAN_UPDATE_CHECKOUT_DATA = 'CAN_UPDATE_CHECKOUT_DATA',
  CAN_ACCESS_LOW_LEVEL_APPLICATION_DATA = 'CAN_ACCESS_LOW_LEVEL_APPLICATION_DATA',
  CAN_ACCESS_ACTIVITY_LOG = 'CAN_ACCESS_ACTIVITY_LOG',
  CAN_ACCESS_COMPANY_SESSION_LOOKUP_REPORT = 'CAN_ACCESS_COMPANY_SESSION_LOOKUP_REPORT',
  CAN_ACCESS_API_EXPLORER = 'CAN_ACCESS_API_EXPLORER',
  CAN_ACCESS_BRIGHTBACK_JS = 'CAN_ACCESS_BRIGHTBACK_JS',
  CAN_ACCESS_LIFECYCLE_JS = 'CAN_ACCESS_LIFECYCLE_JS',
  CAN_ACCESS_QUERY_PLAYGROUND = 'CAN_ACCESS_QUERY_PLAYGROUND',
  CAN_ACCESS_USER_DATA = 'CAN_ACCESS_USER_DATA',
  CAN_ACCESS_TASK_MANAGER_SCHEDULER = 'CAN_ACCESS_TASK_MANAGER_SCHEDULER',
  CAN_ACCESS_TASK_MANAGER = 'CAN_ACCESS_TASK_MANAGER',
  CAN_ADMIN_FEATURE_FLAGS = 'CAN_ADMIN_FEATURE_FLAGS',
  CAN_ACCESS_DEMO_PAGE = 'CAN_ACCESS_DEMO_PAGE',

  EXPERIENCE_MANAGER_OTHER_ACTION_ACCESSIBILE = 'EXPERIENCE_MANAGER_OTHER_ACTION_ACCESSIBILE',
  FIELD_MAPPING_REMOVAL = 'FIELD_MAPPING_REMOVAL',
  ACTIVATE_TEXT_EDITOR_LINK_BUTTON = 'ACTIVATE_TEXT_EDITOR_LINK_BUTTON',
  SHOW_ADMIN_USERS_PAGE_OVERRIDE = 'SHOW_ADMIN_USERS_PAGE_OVERRIDE',
  SHOW_API_KEYS_IN_SETUP_PAGE = 'SHOW_API_KEYS_IN_SETUP_PAGE',
  ACTIVITY_LOG_NOTIFICATIONS = 'ACTIVITY_LOG_NOTIFICATIONS',
  BYPASS = 'BYPASS',
  SHOW_DELETE_CTA_BUTTON = 'SHOW_DELETE_CTA_BUTTON',
  SHOW_GEN_AI = 'SHOW_GEN_AI',
  ALLOW_SAVING_INVALID_OFFER_FE = 'ALLOW_SAVING_INVALID_OFFER_FE',
  SHOW_WELCOME_POPUP = 'SHOW_WELCOME_POPUP',
  HIDE_DEFAULT_BRAND = 'HIDE_DEFAULT_BRAND',
  ADDITIONAL_BRANDS_ENABLED = 'ADDITIONAL_BRANDS_ENABLED',
  SHOW_EXPORT_RAW_ON_EXPPERF_OFFERPERF = 'SHOW_EXPORT_RAW_ON_EXPPERF_OFFERPERF',
  DISABLE_SELF_SERVE_CUSTOM_DOMAIN_VERIFICATION = 'DISABLE_SELF_SERVE_CUSTOM_DOMAIN_VERIFICATION',
  ENABLE_DEMO_REPORTS = 'ENABLE_DEMO_REPORTS',
  ENABLE_PLAN_SEARCH_BY_ID_IN_OFFER_CTAS = 'ENABLE_PLAN_SEARCH_BY_ID_IN_OFFER_CTAS',
  ENABLE_REPORT_BRAND_FILTER = 'ENABLE_REPORT_BRAND_FILTER',
  MULTI_ACTION_CTAS = 'MULTI_ACTION_CTAS',
  SHOW_WHATS_NEW = 'SHOW_WHATS_NEW',
  CAN_ACCESS_CUSTOMER_SUBSCRIPTION = 'CAN_ACCESS_CUSTOMER_SUBSCRIPTION',
  SHOW_VIEWED_ACCEPTED_OFFER_RESERVED_FIELDS = 'SHOW_VIEWED_ACCEPTED_OFFER_RESERVED_FIELDS',
  LA_CARD_WITH_OFFERS = 'LA_CARD_WITH_OFFERS',
  PAGES_LIST_PAGE_AUDIENCES_AND_OFFER_INFO_ENABLED = 'PAGES_LIST_PAGE_AUDIENCES_AND_OFFER_INFO_ENABLED',
  SHOW_SUBMIT_SECTION_STICKINESS_CONFIG = 'SHOW_SUBMIT_SECTION_STICKINESS_CONFIG',
  ENABLE_SURVEY_REASONS_FOR_MULTI_BRAND = 'ENABLE_SURVEY_REASONS_FOR_MULTI_BRAND',
  SHOW_ORDER_SECTIONS_CONTROLS = 'SHOW_ORDER_SECTIONS_CONTROLS',
  SHOW_LA_LAYOUT_CONTROLS = 'SHOW_LA_LAYOUT_CONTROLS',
  ENABLE_LIFECYCLE_OFFERS = 'ENABLE_LIFECYCLE_OFFERS',
  SHOW_CBIDP_FLOW = 'SHOW_CBIDP_FLOW',
  PLAY_UI_FOR_DEMO = 'PLAY_UI_FOR_DEMO',
  ENABLE_LLM_FEEDBACK_CLASSIFIER = 'ENABLE_LLM_FEEDBACK_CLASSIFIER',
  ENABLE_LIFECYCLE_HOSTED_PAGES = 'ENABLE_LIFECYCLE_HOSTED_PAGES',
  SHOW_TRIAL_CONVERSION_OFFERS = 'SHOW_TRIAL_CONVERSION_OFFERS',
  ENABLE_LIFECYCLE_REPORTS = 'ENABLE_LIFECYCLE_REPORTS',
  CAN_REUSE_SAME_BRIGHTBACK_REASON = 'CAN_REUSE_SAME_BRIGHTBACK_REASON',
}

export function useFeatureFlags(featureFlag: FeatureFlagsE): boolean {
  const { data: featureFlags } = useQueryFeatureFlags();

  return useMemo(
    () => featureFlags.includes(featureFlag),
    [featureFlags, featureFlag]
  );
}
