import { useCallback, useMemo } from 'react';
import { toDictionary } from '@bbkAdminRedux/redux_utils';
import { PublishState } from '@bbkAdminUtils/api-client/rest-offers-api';
import { bbkApiSlice } from '@bbkAdminRedux/rtkq/bbkApi.slice';
import type {
  FunnelPlacements,
  PlacementsChangeRequest,
  PlacementsResponse,
  RestFunnelPlacement,
  RestFunnelPlacementFinalOfferPlacement,
  RestFunnelPlacementFirstLoadOfferPlacement,
  RestFunnelPlacementLossAversion,
  RestHostedPlacementOfferCardpack,
} from '@bbkAdminRedux/rtkq/placements.utils';
import {
  formatForReducer,
  PlacementContextType,
  PlacementLossAversionPosition,
  PlacementModalOfferPosition,
} from '@bbkAdminRedux/rtkq/placements.utils';
import {
  FunnelWarning,
  FunnelWarnings,
  PlacementsWarningsResponse,
  RestContent,
  Warning,
  WarningType,
} from '@bbkAdminUtils/api-client/rest-placement-warnings-api';
import { chain, omit } from 'lodash';
import { Dictionary } from '@bbkAdminUtils/utility-types';
import { useSelector } from 'react-redux';
import { selectAppId } from '@bbkAdminRedux/app/selectors';
import { buildWarningMessage } from '@bbkAdminComponents/experiences/offers/placement-warning';
import { FunnelType } from '@bbkAdminRedux/rtkq/funnels.slice';

type PlacementContext =
  | { placementContext?: undefined }
  | {
      placementContext: PlacementContextType.content;
      contentId: string;
    }
  | {
      placementContext: PlacementContextType.reasons;
    };

export type GetPlacementsArgs = {
  appKey: string;
  funnel_type?: FunnelType;
  publishState?: PublishState;
} & (
  | { context?: undefined }
  | {
      context: PlacementContextType.content;
      contentId: string;
    }
  | {
      context: PlacementContextType.reasons;
    }
);

type UpdatePlacementArgs = {
  appKey: string;
  funnelId: number;
} & PlacementsChangeRequest;

type DryPlacementsUpdateArgs = {
  appKey: string;
  request: Record<number, PlacementsChangeRequest>;
} & PlacementContext;

export const placementsSlice = bbkApiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getPlacements: builder.query<FunnelPlacements[], GetPlacementsArgs>({
      query: (args) => {
        let query = `publishState=${args.publishState ?? PublishState.draft}`;
        if (args.context) {
          query += `&placementContext=${args.context}`;
          if (args.context === PlacementContextType.content) {
            query += `&contentId=${args.contentId}`;
          }
        }
        if (args.funnel_type) {
          query += `&funnel_type=${args.funnel_type}`;
        }
        return `/api/v1/apps/${args.appKey}/context_aware_content_placements?${query}`;
      },
      transformResponse: (res: PlacementsResponse) => formatForReducer(res),
      providesTags: ['Placements'],
    }),
    updatePlacement: builder.mutation<void, UpdatePlacementArgs>({
      query: ({ appKey, funnelId, ...body }) => ({
        url: `/api/v1/apps/${appKey}/funnels/${funnelId}/content_placement_change_requests`,
        method: 'PUT',
        body,
      }),
      invalidatesTags: (result, error, arg) => {
        return [
          { type: 'Funnels' },
          { type: 'Placements' },
          { type: 'Offers' },
          { type: 'PageAndOfferCounts' },
          { type: 'Experience', id: `${arg.funnelId}-live` },
          { type: 'Experience', id: `${arg.funnelId}-draft` },
        ];
      },
    }),
    dryUpdatePlacement: builder.mutation<
      FunnelWarnings,
      DryPlacementsUpdateArgs
    >({
      query: ({ appKey, request, ...placementContext }) => ({
        url: `/api/v1/apps/${appKey}/content_placement_publish_warnings`,
        method: 'POST',
        params: placementContext,
        body: request,
      }),
      transformResponse: (res: PlacementsWarningsResponse) => {
        const response = res;
        const funnelsWarnings = [
          ...response.content_publish_warnings.flatMap((cw) =>
            cw.funnels.map(
              (f) =>
                ({
                  type: WarningType.content,
                  funnel: f,
                  content: cw.content,
                }) as FunnelWarning
            )
          ),
          ...response.position_warnings.flatMap((pw) =>
            pw.position_warnings.map(
              (p) =>
                ({
                  type: WarningType.position,
                  funnel: pw.funnel,
                  position: p.position,
                  content: p.content,
                }) as FunnelWarning
            )
          ),
          ...response.reason_warnings.flatMap((rw) =>
            rw.reason_warnings.map(
              (r) =>
                ({
                  type: WarningType.vanityReason,
                  funnel: rw.funnel,
                  vanityReason: r.vanity_reason,
                  content: r.content,
                }) as FunnelWarning
            )
          ),
        ];

        const funnelWarnings: FunnelWarnings = chain(funnelsWarnings)
          .groupBy((w) => w.funnel.id)
          .values()
          .map((fsws) => ({
            funnel: fsws[0]!.funnel,
            warnings: fsws.map((fw) => omit(fw, ['funnel']) as Warning),
          }))
          .value();

        return funnelWarnings;
      },
    }),
  }),
});

export const useSelectAllPlacements = (args: GetPlacementsArgs) => {
  const { data } = placementsSlice.endpoints.getPlacements.useQuery(args);

  return useMemo(() => data ?? [], [data]);
};

export const useSelectAllPlacementsEntities = (args: GetPlacementsArgs) => {
  const placements = useSelectAllPlacements(args);

  return useMemo(() => toDictionary(placements), [placements]);
};

export const useSelectPlacementsByFunnelId = (
  args: GetPlacementsArgs,
  funnelId: number
) => {
  const placementsMap = useSelectAllPlacementsEntities(args);

  return useMemo(() => placementsMap[funnelId], [placementsMap, funnelId]);
};

export const isLossAversionPlacement = (
  placement: RestFunnelPlacement
): placement is RestFunnelPlacementLossAversion =>
  !!placement.position &&
  Object.keys(PlacementLossAversionPosition).includes(placement.position);

export const isFirstLoadPlacement = (
  placement: RestFunnelPlacement
): placement is RestFunnelPlacementFirstLoadOfferPlacement =>
  placement.position === PlacementModalOfferPosition.first_load_offer;

export const isFinalOfferPlacement = (
  placement: RestFunnelPlacement
): placement is RestFunnelPlacementFinalOfferPlacement =>
  placement.position === PlacementModalOfferPosition.final_offer;

export const useSelectFinalStepPlacementsByFunnelId = (
  args: GetPlacementsArgs,
  funnelId: number
) => {
  const placement = useSelectPlacementsByFunnelId(args, funnelId);

  return useMemo(
    () => placement?.placements.find(isFinalOfferPlacement),
    [placement]
  );
};

export const useSelectLossAversionPlacementsByFunnelId = (
  args: GetPlacementsArgs,
  funnelId: number
) => {
  const placement = useSelectPlacementsByFunnelId(args, funnelId);
  return useMemo(
    () => placement?.placements.filter(isLossAversionPlacement) ?? [],
    [placement]
  );
};

export const createFirstLoadPlacement = (
  args: Pick<
    RestFunnelPlacementFirstLoadOfferPlacement,
    'content_id' | 'funnel_id'
  >
): RestFunnelPlacementFirstLoadOfferPlacement => ({
  position: PlacementModalOfferPosition.first_load_offer,
  ...args,
});

export const createLossAversionPlacement = (
  args: Pick<RestFunnelPlacementLossAversion, 'position' | 'funnel_id'>
): RestFunnelPlacementLossAversion => args;

export const createOfferCardpackPlacement = (
  args: Pick<RestHostedPlacementOfferCardpack, 'position' | 'funnel_id'>
): RestHostedPlacementOfferCardpack => args;

export const createFinalOfferPlacement = (
  args: Pick<RestFunnelPlacementFinalOfferPlacement, 'content_id' | 'funnel_id'>
): RestFunnelPlacementFinalOfferPlacement => ({
  position: PlacementModalOfferPosition.final_offer,
  ...args,
});

export const useFetchWarningsForContent = () => {
  const appId = useSelector(selectAppId);
  const [dryUpdatePlacement] =
    placementsSlice.endpoints.dryUpdatePlacement.useMutation();

  return useCallback(
    async (
      content: RestContent,
      funnelsPlacementsMap: Dictionary<FunnelPlacements>
    ) => {
      const warningsRequest = Object.fromEntries(
        Object.entries(funnelsPlacementsMap).map(([funnelId, el]) => [
          funnelId,
          {
            comment: '',
            publish_state: PublishState.live,
            placements: el!.placements,
          },
        ])
      );
      const warnings = await dryUpdatePlacement({
        appKey: appId,
        placementContext: PlacementContextType.content,
        contentId: content.id,
        request: warningsRequest,
      }).unwrap();
      if (warnings.length === 0) return undefined;
      return buildWarningMessage(warnings);
    },
    [appId, dryUpdatePlacement]
  );
};

export const useFetchWarningsForReasons = () => {
  const appId = useSelector(selectAppId);
  const [dryUpdatePlacement] =
    placementsSlice.endpoints.dryUpdatePlacement.useMutation();

  return useCallback(
    async (
      funnelsPlacementsMap: Dictionary<RestFunnelPlacement[]>
    ): Promise<React.ReactNode | undefined> => {
      const warningsRequest = Object.fromEntries(
        Object.entries(funnelsPlacementsMap).map(([funnelId, placements]) => [
          funnelId,
          {
            comment: '',
            publish_state: PublishState.live,
            placements: placements!,
          },
        ])
      );
      const warnings = await dryUpdatePlacement({
        appKey: appId,
        request: warningsRequest,
        placementContext: PlacementContextType.reasons,
      }).unwrap();

      if (warnings.length === 0) return undefined;
      return buildWarningMessage(warnings);
    },
    [appId, dryUpdatePlacement]
  );
};
